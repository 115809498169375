import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Home from "./components/pages/Home";
import Registration from "./components/pages/Registration";
import Login from "./components/pages/Login";
import Profiles from "./components/pages/Profiles";
import SettingsPage from "./components/pages/SettingsPage";
import FileManagement from "./components/pages/FileManagement";
import NoAuth from "./components/pages/NoAuth";
import PageNotFound from "./components/pages/PageNotFound";
import PrivateRoute from "./components/PrivateRoute";
import { setUser } from "./redux/actions/userActions"; // Import setUser action
import Myprofile from "./components/pages/Myprofile";
import MyResumes from "./components/Myresumes";
import Reports from "./components/Reports";
import ContactUs from "./components/pages/ContactUs";
import UserManagement from "./components/pages/Settings/UserManagement";
import CustomerManagement from "./components/pages/Settings/CustomerManagement";
import AssignmentSettings from "./components/pages/Settings/AssignmentSettings";
import GlobalSettings from "./components/pages/Settings/GlobalSettings";
import UserProfile from "./components/pages/UserProfile";
import Dashboard from "./components/pages/Dashboard";
import NotActivated from "./components/pages/NotActivated";
import Process from "./components/Process";


function App() {
  const dispatch = useDispatch();
  const { license_code = "" } = useSelector((state) => state.user || {});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Simulate fetching user details and set them in Redux store
      const user = {
        isAuthenticated: true,
        role: "admin", // Example role
        license_code: "jinn_hire_chat", // Example license code
      };
      dispatch(setUser(user));
    }
  }, [dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
          {/* Role and License-based Routes */}
          {/* {license_code === "jinn_hire_chat" && ( */}
            <Route path="/home" element={<Home />} />
          {/* )} */}
          {/* {license_code === "jinnhire_match" &&  */}
          <Route path="/profiles" element={<Profiles />} />
          {/* } */}
          {/* {license_code === "jinnhire_scout" &&  */}
          <Route path="/files" element={<FileManagement />} />
          {/* } */}
          <Route path="/userprofile" element={<UserProfile />} />
          {/* Routes accessible without license code */}
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/myprofile" element={<Myprofile />} />
          <Route path="/process" element={<Process />} />
          <Route path="/myresumes" element={<MyResumes />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/not-activated" element={<NotActivated/>} />
          {/* Routes for settings */}
          <Route path="/user" element={<UserManagement />} />
          <Route path="/customer" element={<CustomerManagement />} />
          <Route path="/assignment" element={<AssignmentSettings />} />
          <Route path="/globalSettings" element={<GlobalSettings />} />
        </Route>

        <Route path="/signup" element={<Registration />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/" element={<Login />} />
        <Route path="/noauth" element={<NoAuth />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;