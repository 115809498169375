import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Button,
} from '@mui/material';
import { blue, grey, common } from '@mui/material/colors';

// Styles for the modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  height: '80%',
  bgcolor: common.white,
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

// Styles for the question list
const questionListStyle = {
  width: '100%',
  maxHeight: '60vh',
  overflowY: 'auto',
  mb: 2,
};

// Styles for the individual question box
const questionBoxStyle = {
  width: '100%',
  p: 2,
  mb: 2,
  borderRadius: 2,
  bgcolor: blue[50],
  border: `1px solid ${blue[200]}`,
  boxShadow: `0px 4px 6px ${grey[300]}`,
};

// Styles for the close button
const closeButtonStyle = {
  position: 'absolute',
  top: 10,
  right: 10,
  bgcolor: 'red.main',
  color: 'white',
  '&:hover': {
    bgcolor: 'red.dark',
  },
};

const RequirementQuestionsModal = ({ open, handleClose, questions }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" sx={{ mb: 2, color: '#0952a3' }}>
          Questions to Ask the Candidate
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box sx={questionListStyle}>
          <List>
            {questions.map((question, index) => (
              <ListItem key={index} sx={{ p: 0 }}>
                <Paper sx={questionBoxStyle}>
                  <ListItemText
                    primary={
                      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Q{index + 1}: {question}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        Please provide your response below.
                      </Typography>
                    }
                  />
                </Paper>
              </ListItem>
            ))}
          </List>
        </Box>
        <Button onClick={handleClose} variant="contained" sx={closeButtonStyle}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default RequirementQuestionsModal;
