import React, { useState, useEffect } from "react";
import Sidebar from "../../SideBar";
import PropTypes from "prop-types";
import {
  Paper,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  Tooltip,
  Toolbar,
  Container,
  FormControl,
  Checkbox,
} from "@mui/material";
import BreadCrumb from "../../BreadCrumb";
import UserAssignments from "../../UserAssignments";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { Select } from "@mui/material";
import UserActivate from "../../UserActivate";

export default function UserManagement() {
  const [userTabValue, setUserTabValue] = useState(0);
  const [clients, setClients] = useState([]);
  const [excelFiles, setExcelFiles] = useState([]);
  const [customerAssignments, setCustomerAssignments] = useState([]);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [noRequirementsCustomers, setNoRequirementsCustomers] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [address, setAddress] = useState("");
  const [domain, setDomain] = useState("");
  const [industry, setIndustry] = useState("");
  const [aboutCustomer, setAboutCustomer] = useState("");
  const [customerPDF, setCustomerPDF] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailId, setEmailId] = useState("");
  const [role, setRole] = useState("");
  const [roleDivision, setRoleDivision] = useState("");
  const [loginrole, setLoginRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+12707478852");
  const [customerAssignment, setCustomerAssignment] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [tenantData, setTenantData] = useState(null);

  const handleUserTabChange = (event, newValue) => {
    setUserTabValue(newValue);
  };

  const handleUserSubmit = async (event) => {
    event.preventDefault();
    console.log(customerAssignment, "CA");
    const customerIds = customerAssignment.map((ca) => ca.id);

    const payload = {
      first_name: firstName,
      last_name: username,
      username,
      password,
      email: emailId,
      role,
      phone_number: phoneNumber,
      customer_ids: customerIds,
      is_active_for_assignment: true,
    };

    const loggedInUserEmail = localStorage.getItem("username");
    const loggedInUserDomain = loggedInUserEmail.split("@")[1];

    const newUserDomain = emailId.split("@")[1];

    if (newUserDomain !== loggedInUserDomain) {
      console.error(
        "The new user email domain does not match the logged-in user domain."
      );

      setShow(true);
      setSeverity("warning");
      setMessage(
        "The new user email domain must match the logged-in user domain."
      );
    } else {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.jinnhire.in/jinnhire/data/tenants/current/?domain=${loggedInUserDomain}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        const tenantData = response.data;
        payload.tenant_id = tenantData.id;

        await axios.post(
          "https://api.jinnhire.in/jinnhire/data/users/",
          payload,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setShow(true);
        setSeverity("success");
        setMessage("User created successfully!");

        // Reset input fields after successful creation
        setFirstName("");
        setLastName("");
        setUsername("");
        setPassword("");
        setEmailId("");
        setRole("");
        setPhoneNumber("+12707478852");
        setCustomerAssignment([]);
      } catch (error) {
        console.error("Error creating user:", error);

        setShow(true);
        setSeverity("error");
        if (error.response.data.message) {
          setMessage(error.response.data.message);
        } else {
          setMessage("Failed to create user.");
        }
      }
    }
  };

  // Function to fetch all customers and their requirement types
  const fetchCustomersWithRequirements = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.jinnhire.in/jinnhire/data/customers/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const customersData = response.data;

      // Fetch requirement types for each customer
      const customersWithRequirements = await Promise.all(
        customersData.map(async (customer) => {
          try {
            const requirementResponse = await axios.get(
              `https://api.jinnhire.in/jinnhire/data/customers/${customer.id}/requirement-type/`,
              {
                headers: {
                  Authorization: `Token ${token}`,
                },
              }
            );
            console.log(requirementResponse.data.requirement_type, "REQTYPE");
            return {
              ...customer,
              requirement_type:
                requirementResponse.data.requirement_type || "No requirements",
            };
          } catch (error) {
            console.error(
              `Error fetching requirement type for customer ${customer.id}:`,
              error
            );
            return {
              ...customer,
              requirement_type: "No requirements",
            };
          }
        })
      );
      setCustomers(customersWithRequirements);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setShow(true);
      setSeverity("error");
      setMessage("Failed to fetch customers.");
    }
  };

  useEffect(() => {
    fetchTenantData();
    const userData = localStorage.getItem("user");
    const getrole = JSON.parse(userData);
    // console.log(getrole.role,"ROLE")
    setLoginRole(getrole.role);
  }, []);

  const fetchTenantData = async () => {
    try {
      const token = localStorage.getItem("token");
      const loggedInUserEmail = localStorage.getItem("username");
      const loggedInUserDomain = loggedInUserEmail.split("@")[1];
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/tenants/current/?domain=${loggedInUserDomain}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setTenantData(response.data);
      console.log("Tenant data:", response.data);
    } catch (error) {
      console.error("Error fetching tenant data:", error);
    }
  };

  useEffect(() => {
    fetchCustomersWithRequirements();
  }, []);

  // Filter customers based on role division
  useEffect(() => {
    if (roleDivision) {
      let filtered;
      const normalizedRoleDivision = roleDivision.toLowerCase(); // Normalize input

      if (normalizedRoleDivision === "all") {
        filtered = customers.filter(
          (customer) =>
            customer.requirement_type &&
            (customer.requirement_type.toLowerCase() === "no requirements" ||
              customer.requirement_type.toLowerCase() === "permanent" ||
              customer.requirement_type.toLowerCase() === "contract" ||
              customer.requirement_type.toLowerCase() === "global")
        );
      } else {
        filtered = customers.filter(
          (customer) =>
            customer.requirement_type &&
            customer.requirement_type.toLowerCase() === normalizedRoleDivision
        );
      }
      setFilteredAssignments(filtered);
    } else {
      setFilteredAssignments(customers);
    }
  }, [roleDivision, customers]);


  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>
          <BreadCrumb />
          <Box>
            <Snackbar
              open={show}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              autoHideDuration={6000}
              message="Success"
              onClose={() => setShow(false)}
            >
              <Alert
                onClose={() => setShow(false)}
                severity={severity}
                sx={{ width: "100%" }}
              >
                {message}
              </Alert>
            </Snackbar>
            <Paper sx={{ p: 4, backgroundColor: "" }}>
              <Typography variant="h5" gutterBottom>
                User Management
              </Typography>
              <Tabs
                value={userTabValue}
                onChange={handleUserTabChange}
                aria-label="user management tabs"
                centered
                sx={{ mb: 3 }}
              >
                <Tab label="Add New User" />
                <Tab label="User Assignment" />
                <Tab label="Activate User" />
              </Tabs>
              <TabPanel value={userTabValue} index={0}>
                <Paper sx={{ p: 4, backgroundColor: "" }}>
                  <div id="createUser" className="user-form">
                    <form onSubmit={handleUserSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">First Name</Typography>
                          <TextField
                            id="firstName"
                            size="small"
                            fullWidth
                            required
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">Last Name</Typography>
                          <TextField
                            id="lastName"
                            size="small"
                            fullWidth
                            required
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Username</Typography>
                          <TextField
                            id="username"
                            size="small"
                            fullWidth
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Password</Typography>
                          <TextField
                            id="password"
                            type="password"
                            size="small"
                            fullWidth
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Email ID</Typography>
                          <TextField
                            id="emailId"
                            type="email"
                            size="small"
                            fullWidth
                            required
                            value={emailId}
                            onChange={(e) => setEmailId(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Role</Typography>
                          <TextField
                            id="role"
                            select
                            size="small"
                            fullWidth
                            required
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                          >
                            <MenuItem value="recruiter_sourcing">
                              Recruiter-Sourcing
                            </MenuItem>
                            <MenuItem value="recruiter_processing">
                              Recruiter-Processing
                            </MenuItem>
                            <MenuItem value="recruiter_all">
                              Recruiter-All
                            </MenuItem>
                            <MenuItem value="lead_sourcing">
                              Lead-Sourcing
                            </MenuItem>
                            <MenuItem value="lead_processing">
                              Lead-Processing
                            </MenuItem>
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="manager">Manager</MenuItem>
                            <MenuItem value="account_manager">
                              Account-Manager
                            </MenuItem>
                            <MenuItem value="internal_recruiter_processing">
                              Internal Recruiter Processing
                            </MenuItem>
                            <MenuItem value="external_recruiter_processing">
                              External Recruiter Processing
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Role Division</Typography>
                          <TextField
                            id="role_division"
                            select
                            size="small"
                            fullWidth
                            required
                            value={roleDivision}
                            onChange={(e) => setRoleDivision(e.target.value)}
                          >
                            <MenuItem value="permanent">Permanent</MenuItem>
                            <MenuItem value="contract">Contract</MenuItem>
                            <MenuItem value="global">Global</MenuItem>
                            <MenuItem value="all">All</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">Phone Number</Typography>
                          <TextField
                            id="phoneNumber"
                            size="small"
                            fullWidth
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            Customer Assignment
                          </Typography>
                          <Tooltip
                            title={
                              roleDivision
                                ? "Select one or more customers to assign to this user"
                                : "Select a Role Division to enable"
                            }
                            placement="top"
                          >
                            <div>
                              <FormControl fullWidth>
                                <Select
                                  id="customerAssignment"
                                  multiple
                                  value={customerAssignment.map((ca) => ca.id)} // Store only IDs in the state
                                  onChange={(e) => {
                                    const selectedIds = e.target.value; // Get selected IDs
                                    const selectedCustomers =
                                      filteredAssignments.filter(
                                        (customer) =>
                                          selectedIds.includes(customer.id) // Get full customer objects
                                      );
                                    setCustomerAssignment(selectedCustomers); // Update state with full objects
                                  }}
                                  disabled={!roleDivision}
                                  renderValue={(selected) =>
                                    selected.length
                                      ? selected
                                          .map((id) => {
                                            const customer =
                                              filteredAssignments.find(
                                                (c) => c.id === id
                                              );
                                            return customer
                                              ? customer.name
                                              : "";
                                          })
                                          .join(", ")
                                      : "None selected"
                                  }
                                >
                                  <MenuItem>
                                    <Checkbox
                                      checked={
                                        customerAssignment.length ===
                                          filteredAssignments.length &&
                                        filteredAssignments.length > 0
                                      }
                                      onChange={(e) => {
                                        e.stopPropagation(); // Prevent the select from closing
                                        if (
                                          customerAssignment.length ===
                                          filteredAssignments.length
                                        ) {
                                          setCustomerAssignment([]); // Deselect all
                                        } else {
                                          setCustomerAssignment(
                                            filteredAssignments
                                          ); // Select all
                                        }
                                      }}
                                    />
                                    <span>Select All</span>
                                  </MenuItem>
                                  {filteredAssignments.map((customer) => (
                                    <MenuItem
                                      key={customer.id}
                                      value={customer.id}
                                    >
                                      <Checkbox
                                        checked={customerAssignment.some(
                                          (c) => c.id === customer.id
                                        )} // Check if the customer is selected
                                        onChange={(e) => {
                                          e.stopPropagation(); // Prevent the select from closing
                                          if (e.target.checked) {
                                            // Select
                                            setCustomerAssignment([
                                              ...customerAssignment,
                                              customer,
                                            ]);
                                          } else {
                                            // Deselect
                                            setCustomerAssignment(
                                              customerAssignment.filter(
                                                (c) => c.id !== customer.id
                                              )
                                            );
                                          }
                                        }}
                                      />
                                      {customer.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </Tooltip>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                          >
                            Create User
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                  {/* <div id="updateUser" className="user-form" style={{ display: 'none' }}>
       <h3>Update Existing User</h3>
       <form id="updateUserForm" onSubmit={handleUserSubmit}>
         
         <Button type="submit" variant="contained">Update User</Button>
       </form>
     </div> */}
                </Paper>
              </TabPanel>
              <TabPanel value={userTabValue} index={1}>
                <UserAssignments />
              </TabPanel>
              <TabPanel value={userTabValue} index={2}>
                <UserActivate />
              </TabPanel>
            </Paper>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
