export const SET_REQUIREMENTS = "SET_REQUIREMENTS";

export const setRequirements = (requirements) => ({
  type: SET_REQUIREMENTS,
  payload: requirements,
});

// Async action to fetch requirements
export const fetchRequirements = (userId) => {
  return async (dispatch) => {
    console.log("Fetching requirements..."); // Debug log
    const token = localStorage.getItem("token"); // Retrieve token from localStorage

    try {
      const response = await fetch('https://api.jinnhire.in/jinnhire/data/requirements/', {
        headers: {
          Authorization: `Token ${token}`, // Include the token in the headers
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const requirementsData = await response.json();

      // Process requirements to include active state and opt-in counts
      const requirementsWithActiveState = requirementsData.map(requirement => {
        const isActive = requirement.users_opted.includes(userId);
        return { ...requirement, active: isActive };
      });

      const optInCountsData = requirementsData.reduce((acc, requirement) => {
        acc[requirement.requirement_id] = requirement.users_opted.length;
        return acc;
      }, {});

      // Dispatch requirements
      dispatch(setRequirements(requirementsWithActiveState));
      return optInCountsData; // Return opt-in counts for further use
    } catch (error) {
      console.error("Failed to fetch requirements:", error);
      throw error; // Rethrow to handle in useEffect
    }
  };
};