import { Box, Container, Paper, Toolbar, Typography } from "@mui/material";
import React from "react";
import Sidebar from "../../SideBar";
import BreadCrumb from "../../BreadCrumb";
import TopBar from "../../TopBar";
import Assignments from "../../Assignments";

export default function AssignmentSettings() {
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>
          <BreadCrumb />
          <Paper sx={{ p: 4, backgroundColor: "" }}>
                <Typography variant="h5" gutterBottom>
                  Assignment Management
                </Typography>
                <Assignments />
              </Paper>
        </Container>
      </Box>
    </Box>
  );
}
