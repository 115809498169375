import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import BreadCrumb from "./BreadCrumb";
import Assignments from "./Assignments";
import UserAssignments from "./UserAssignments";
import { Alert, IconButton, Select, Snackbar, Tooltip } from "@mui/material";
import GlobalSettings from "./pages/Settings/GlobalSettings";
import { AttachFile } from "@mui/icons-material";
import UserSelectionModal from "./UserSelectionModal";

const SettingsTab = () => {
  const [value, setValue] = useState(0);
  const [userTabValue, setUserTabValue] = useState(0); // For nested User tab
  const [clients, setClients] = useState([]);
  const [excelFiles, setExcelFiles] = useState([]);
  const [customerAssignments, setCustomerAssignments] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [address, setAddress] = useState("");
  const [domain, setDomain] = useState("");
  const [industry, setIndustry] = useState("");
  const [aboutCustomer, setAboutCustomer] = useState("");
  const [customerSuggestions, setCustomerSuggestions] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerPDF, setCustomerPDF] = useState(null);
  const [agreementPDF, setAgreementPDF] = useState(null);
  const [daysOfPayment, setDaysOfPayment] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");

  // Error state for inputs
  const [errors, setErrors] = useState({
    customerName: "",
    address: "",
    domain: "",
    industry: "",
    daysOfPayment: "",
    paymentAmount: "",
    customerPDF: "",
    agreementPDF: "",
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailId, setEmailId] = useState("");
  const [role, setRole] = useState("");
  const [loginrole, setLoginRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+12707478852");
  const [customerAssignment, setCustomerAssignment] = useState([]);
  const [customerId, setCustomerId] = useState(null);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [tenantData, setTenantData] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUserTabChange = (event, newValue) => {
    setUserTabValue(newValue);
  };

  // Handle change and validation for input fields
  const handleInputChange = (field, value) => {
    switch (field) {
      case "customerName":
        setCustomerName(value);
        setErrors({
          ...errors,
          customerName: value ? "" : "Customer name is required",
        });
        break;
      case "address":
        setAddress(value);
        setErrors({
          ...errors,
          address: value ? "" : "Address is required",
        });
        break;
      case "domain":
        setDomain(value);
        setErrors({
          ...errors,
          domain: value ? "" : "Domain is required",
        });
        break;
      case "industry":
        setIndustry(value);
        setErrors({
          ...errors,
          industry: value ? "" : "Industry is required",
        });
        break;
      case "daysOfPayment":
        setDaysOfPayment(value);
        setErrors({
          ...errors,
          daysOfPayment: value ? "" : "Days of payment is required",
        });
        break;
      case "aboutCustomer":
        setAboutCustomer(value);
        break;
      case "customerPDF":
        setCustomerPDF(value);
        setErrors({
          ...errors,
          customerPDF: value ? "" : "customer PDF is required",
        });
        break;
      case "agreementPDF":
        setAgreementPDF(value);
        setErrors({
          ...errors,
          agreementPDF: value ? "" : "Agreement PDF is required",
        });
        break;
      case "paymentAmount":
        setPaymentAmount(value);
        setErrors({
          ...errors,
          paymentAmount: value ? "" : "Payment amount is required",
        });
        break;
      default:
        break;
    }
  };

  const handleCustomerSubmit = async (event) => {
    event.preventDefault();
    handleOpenModal(true);
    // Form validation before submission
    if (
      !customerName ||
      !address ||
      !domain ||
      !industry ||
      !daysOfPayment ||
      !paymentAmount ||
      !customerPDF ||
      !agreementPDF
    ) {
      setErrors({
        customerName: customerName ? "" : "Customer name is required",
        address: address ? "" : "Address is required",
        domain: domain ? "" : "Domain is required",
        industry: industry ? "" : "Industry is required",
        daysOfPayment: daysOfPayment ? "" : "Days of payment is required",
        paymentAmount: paymentAmount ? "" : "Payment amount is required",
        customerPDF: customerPDF ? "" : "Customer PDF is required",
        agreementPDF: agreementPDF ? "" : "Agreement PDF is required",
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", customerName);
    formData.append("address", address);
    formData.append("domain", domain);
    formData.append("industry", industry);
    formData.append("about", aboutCustomer);
    formData.append("tenant", tenantData.id);
    formData.append("customer_pdf", customerPDF); // Append customerPDF to formData
    formData.append("customer_agreement_pdf", agreementPDF); // Append agreement PDF to formData
    formData.append("days_to_payment", daysOfPayment);
    formData.append("payment_amount", paymentAmount);

    // Append Excel file data
    excelFiles.forEach((file, index) => {
      formData.append(`division`, file.divisionName);
      formData.append(`target_audience`, file.targetAudience);
      formData.append(`customer_enablement_excel[]`, file.excel);
    });

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `https://api.jinnhire.in/jinnhire/data/customers/`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setShow(true);
      setSeverity("success");
      setMessage("Customer created successfully!");

      // Reset input fields after successful creation
      setCustomerSuggestions([]);
      setCustomerName("");
      setAddress("");
      setDomain("");
      setIndustry("");
      setAboutCustomer("");
      setDaysOfPayment(""); // Reset days_of_payment
      setPaymentAmount(""); // Reset payment_amount
      setAgreementPDF(null); // Reset agreement PDF
      setCustomerPDF(null); // Reset file input
      setExcelFiles([]); // Reset excel files input
      fetchCustomerAssignments();

      setCustomerId(response.data.customer_id);
      handleOpenModal(true);
    } catch (error) {
      console.error("Error creating customer:", error);
      setShow(true);
      setSeverity("error");
      if (error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage("Failed to create customer.");
      }
    }
  };

  const handleUserSubmit = async (event) => {
    event.preventDefault();
    console.log(customerAssignment, "CA");
    const customerIds = customerAssignment.map((ca) => ca.id);

    const payload = {
      first_name: firstName,
      last_name: username,
      username,
      password,
      email: emailId,
      role,
      phone_number: phoneNumber,
      customer_ids: customerIds,
      is_active_for_assignment: true,
    };

    const loggedInUserEmail = localStorage.getItem("username");
    const loggedInUserDomain = loggedInUserEmail.split("@")[1];

    const newUserDomain = emailId.split("@")[1];

    if (newUserDomain !== loggedInUserDomain) {
      console.error(
        "The new user email domain does not match the logged-in user domain."
      );

      setShow(true);
      setSeverity("warning");
      setMessage(
        "The new user email domain must match the logged-in user domain."
      );
    } else {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.jinnhire.in/jinnhire/data/tenants/current/?domain=${loggedInUserDomain}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        const tenantData = response.data;
        payload.tenant_id = tenantData.id;

        await axios.post(
          "https://api.jinnhire.in/jinnhire/data/users/",
          payload,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setShow(true);
        setSeverity("success");
        setMessage("User created successfully!");

        // Reset input fields after successful creation
        setFirstName("");
        setLastName("");
        setUsername("");
        setPassword("");
        setEmailId("");
        setRole("");
        setPhoneNumber("+12707478852");
        setCustomerAssignment([]);
      } catch (error) {
        console.error("Error creating user:", error);

        setShow(true);
        setSeverity("error");
        if (error.response.data.message) {
          setMessage(error.response.data.message);
        } else {
          setMessage("Failed to create user.");
        }
      }
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSelectUsers = (users) => {
    setSelectedUsers(users);
    // Additional logic if needed
  };
  const handleAddClient = () => {
    if (clients.length === 0) {
      setClients([{ name: "", about: "", relationship: "", pdf: null }]);
    }
  };

  const handleDeleteClient = (index) => {
    setClients(clients.filter((_, i) => i !== index));
  };

  const handleAddExcelFile = () => {
    setExcelFiles([
      ...excelFiles,
      { divisionName: "", targetAudience: "", excel: null },
    ]);
  };

  const handleDeleteExcelFile = (index) => {
    setExcelFiles(excelFiles.filter((_, i) => i !== index));
  };

  const handleCustomerSearch = async (name) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/customers/search/?name=${name}&tenant=${tenantData.id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setCustomerSuggestions(response.data);
    } catch (error) {
      console.error("Error fetching customer suggestions:", error);
    }
  };

  const handleCustomerNameChange = (event, value) => {
    setCustomerName(value);
    if (value.length > 2) {
      handleCustomerSearch(value);
    }
  };

  const handleCustomerSelect = (event, value) => {
    if (value) {
      setSelectedCustomer(value);
      setCustomerName(value.name);
      setAddress(value.address);
      setDomain(value.domain);
      setIndustry(value.industry);
      setAboutCustomer(value.about);
    }
  };

  const fetchCustomerAssignments = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.jinnhire.in/jinnhire/data/customers/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setCustomerAssignments(response.data);
    } catch (error) {
      console.error("Error fetching customer assignments:", error);
    }
  };

  const fetchAssignments = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/users`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setAssignments(response.data);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };

  useEffect(() => {
    fetchCustomerAssignments();
    fetchAssignments();
    fetchTenantData();
    const userData = localStorage.getItem("user");
    const getrole = JSON.parse(userData);
    // console.log(getrole.role,"ROLE")
    setLoginRole(getrole.role);
  }, []);

  const fetchTenantData = async () => {
    try {
      const token = localStorage.getItem("token");
      const loggedInUserEmail = localStorage.getItem("username");
      const loggedInUserDomain = loggedInUserEmail.split("@")[1];
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/tenants/current/?domain=${loggedInUserDomain}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setTenantData(response.data);
      console.log("Tenant data:", response.data);
    } catch (error) {
      console.error("Error fetching tenant data:", error);
    }
  };

  const handleExcelFileChange = (index, field, value) => {
    const updatedExcelFiles = [...excelFiles];
    updatedExcelFiles[index][field] = value;
    console.log(updatedExcelFiles, "EXCEL DATA");
    setExcelFiles(updatedExcelFiles);
  };

  const validateExcelFileName = (index) => {
    console.log(industry, "INDUSTRY");
    const industryValue = industry.trim();
    const customerNameValue = customerName.trim();
    const targetAudience = excelFiles[index].targetAudience.trim();
    const divisionName = excelFiles[index].divisionName.trim();
    const expectedFormat = `${industryValue}_${customerNameValue}_${targetAudience}_${divisionName}.xlsx`;
    const fileInput = document.getElementById(
      `customerEnablementExcel-${index}`
    );
    const fileName = fileInput.files[0].name;
    if (fileName !== expectedFormat) {
      setShow(true);
      setSeverity("warning");
      setMessage(`The file must be named exactly as ${expectedFormat}`);
      fileInput.value = ""; // Clear the file input
    }
  };

  return (
    <Box sx={{ width: "100%", typography: "body1", p: 3 }}>
      <Snackbar
        open={show}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
        message="Success"
        onClose={() => setShow(false)}
      >
        <Alert
          onClose={() => setShow(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h5" gutterBottom>
          Add Customer
        </Typography>
        <form onSubmit={handleCustomerSubmit}>
          <Grid container spacing={3}>
            {/* Customer Name */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Customer Name</Typography>
              <Autocomplete
                freeSolo
                options={customerSuggestions}
                getOptionLabel={(option) => option.name || ""}
                onInputChange={handleCustomerNameChange}
                onChange={handleCustomerSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Search for existing customers..."
                    fullWidth
                    variant="outlined"
                    error={!!errors.customerName}
                    helperText={errors.customerName}
                  />
                )}
              />
            </Grid>

            {/* Address */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Address</Typography>
              <TextField
                size="small"
                fullWidth
                multiline
                rows={4}
                value={address}
                onChange={(e) => handleInputChange("address", e.target.value)}
                variant="outlined"
                error={!!errors.address}
                helperText={errors.address}
              />
            </Grid>

            {/* Customer Mail Assignment */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Customer Mail Assignment</Typography>
              <TextField
                size="small"
                fullWidth
                value={domain}
                placeholder="Input dell if the mail-id is dell.demands@nexiiconsulting.online"
                onChange={(e) => handleInputChange("domain", e.target.value)}
                variant="outlined"
                error={!!errors.domain}
                helperText={errors.domain}
              />
            </Grid>

            {/* Industry */}
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">Industry</Typography>
              <TextField
                size="small"
                fullWidth
                value={industry}
                onChange={(e) => handleInputChange("industry", e.target.value)}
                variant="outlined"
                error={!!errors.industry}
                helperText={errors.industry}
              />
            </Grid>

            {/* About Customer */}
            <Grid item xs={12}>
              <Typography variant="body1">About Customer</Typography>
              <TextField
                size="small"
                fullWidth
                multiline
                rows={4}
                value={aboutCustomer}
                onChange={(e) =>
                  handleInputChange("aboutCustomer", e.target.value)
                }
                variant="outlined"
              />
            </Grid>

            {/* File Uploads */}
            <Grid item xs={12}>
              <Typography variant="body1">Upload PDFs</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Tooltip title="Upload a Customer PDF" arrow>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "40px",
                        bgcolor: "#00796B",
                        "&:hover": { bgcolor: "#004D40" },
                        borderRadius: 2,
                        color: "#FFF",
                      }}
                    >
                      <AttachFile sx={{ mr: 1 }} /> Customer PDF
                      <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={(e) =>
                          handleInputChange("customerPDF", e.target.files[0])
                        }
                      />
                    </Button>
                  </Tooltip>
                  {errors.customerPDF && (
                    <Typography color="error">{errors.customerPDF}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* Payments Section */}
            <Grid
              item
              xs={12}
              sx={{ padding: 2, backgroundColor: "#f9f9f9", borderRadius: 4 }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                Payments
              </Typography>

              {/* Days of Payment */}
              <Grid
                item
                xs={12}
                sx={{ mt: 2 }}
                display="flex"
                alignItems="center"
              >
                <Typography variant="body1" sx={{ ml: 1, fontWeight: "500" }}>
                  Number of days to payment
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={daysOfPayment}
                  onChange={(e) =>
                    handleInputChange("daysOfPayment", e.target.value)
                  }
                  inputProps={{ style: { width: "40px", textAlign: "center" } }} // Small width and centered
                  sx={{ mx: 1 }}
                  error={!!errors.daysOfPayment}
                  helperText={errors.daysOfPayment}
                />
                <Typography variant="body1" sx={{ fontWeight: "500" }}>
                  .
                </Typography>
              </Grid>

              {/* Payment Amount */}
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Typography variant="body1" sx={{ ml: 1, fontWeight: "500" }}>
                  Amount Paid is
                </Typography>
                <TextField
                  size="small"
                  variant="outlined"
                  value={paymentAmount}
                  onChange={(e) =>
                    handleInputChange("paymentAmount", e.target.value)
                  }
                  inputProps={{ style: { width: "40px", textAlign: "center" } }} // Small width and centered
                  sx={{ mx: 1 }}
                  error={!!errors.paymentAmount}
                  helperText={errors.paymentAmount}
                />
                <Typography variant="body1" sx={{ fontWeight: "500" }}>
                  % of candidates' annual CTC
                </Typography>
              </Grid>

              {/* Agreement File Upload */}
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "500", color: "#555" }}
                >
                  Upload Agreement File
                </Typography>
                <Grid item xs={12} sm={6}>
                  <Tooltip title="Upload an Agreement PDF" arrow>
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "40px",
                        bgcolor: "#D32F2F",
                        "&:hover": { bgcolor: "#B71C1C" },
                        borderRadius: 2,
                        color: "#FFF",
                        boxShadow: 2,
                        transition: "transform 0.2s",
                        "&:active": {
                          transform: "scale(0.95)",
                        },
                        mt: 1,
                      }}
                    >
                      <AttachFile sx={{ mr: 1 }} /> Agreement PDF
                      <input
                        type="file"
                        hidden
                        accept="application/pdf"
                        onChange={(e) =>
                          handleInputChange("agreementPDF", e.target.files[0])
                        }
                      />
                    </Button>
                  </Tooltip>
                  {errors.agreementPDF && (
                    <Typography color="error" sx={{ mt: 1 }}>
                      {errors.agreementPDF}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/* Excel Files Section */}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Customer Enablement Excel</Typography>
              <Button
                variant="contained"
                onClick={handleAddExcelFile}
                sx={{
                  backgroundColor: "rgb(31, 91, 139)",
                  display: excelFiles.length === 0 ? "block" : "none",
                }}
              >
                + Add Excel File
              </Button>
            </Grid>

            {excelFiles.map((file, index) => (
              <Grid
                item
                xs={12}
                key={index}
                sx={{ mb: 3, position: "relative" }} // Maintain position for absolute positioning of delete icon
              >
                <IconButton
                  color="error"
                  onClick={() => handleDeleteExcelFile(index)}
                  sx={{
                    position: "absolute",
                    top: 10, // Adjusted for better alignment
                    right: 10, // Adjusted for better alignment
                    padding: 0,
                    zIndex: 1, // Ensure it stays above other elements
                  }}
                >
                  <DeleteIcon />
                </IconButton>

                <Grid container spacing={3} sx={{ pt: 2 }}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Division Name</Typography>
                    <TextField
                      id={`divisionName-${index}`}
                      value={file.divisionName}
                      onChange={(e) =>
                        handleExcelFileChange(
                          index,
                          "divisionName",
                          e.target.value
                        )
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">Target Audience</Typography>
                    <TextField
                      id={`targetAudience-${index}`}
                      value={file.targetAudience}
                      onChange={(e) =>
                        handleExcelFileChange(
                          index,
                          "targetAudience",
                          e.target.value
                        )
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1">
                      Customer Enablement Excel
                    </Typography>
                    <Tooltip title="Upload a Customer Enablement Excel" arrow>
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          height: "40px",
                          bgcolor: "#00796b",
                          "&:hover": { bgcolor: "#004D40" },
                          borderRadius: 2,
                          color: "#FFF",
                          boxShadow: 2,
                          transition: "transform 0.2s",
                          "&:active": {
                            transform: "scale(0.95)",
                          },
                        }}
                      >
                        <AttachFile sx={{ mr: 1 }} /> Excel File
                        <input
                          type="file"
                          hidden
                          id={`customerEnablementExcel-${index}`}
                          onChange={(e) => {
                            handleExcelFileChange(
                              index,
                              "excel",
                              e.target.files[0]
                            );
                            validateExcelFileName(index);
                          }}
                          accept=".xlsx,.xls"
                        />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  mt: 3,
                  bgcolor: "#388E3C",
                  "&:hover": { bgcolor: "#1B5E20" },
                  borderRadius: 2,
                  color: "#FFF",
                }}
              >
                Create Customer
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <UserSelectionModal
        open={modalOpen}
        onClose={handleCloseModal}
        users={assignments}
        customerId={customerId}
        onSelectUsers={handleSelectUsers}
      />
    </Box>
  );
};

export default SettingsTab;
