import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import { blue, grey, green, red, common } from '@mui/material/colors';
import { ExpandLess, ExpandMore, Close } from '@mui/icons-material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  maxWidth: 750,
  height: '85%',
  bgcolor: grey[100], 
  boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
  p: 4,
  borderRadius: 12,
  overflowY: 'auto',
  transition: 'all 0.3s ease-in-out',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  mb: 3,
  color: blue[800],
  fontWeight: 600,
};

const tabButtonStyle = (isActive) => ({
  flex: 1,
  borderRadius: 50,
  mr: 1,
  ml: 1,
  padding: '10px 20px',
  textTransform: 'none',
  background: isActive
    ? 'linear-gradient(45deg, #42a5f5, #011b38)'
    : grey[300], 
  color: isActive ? common.white : grey[600],
  boxShadow: isActive ? `0px 4px 12px rgba(0, 0, 0, 0.1)` : 'none',
  '&:hover': {
    background: isActive
      ? 'linear-gradient(45deg, #011b37, #1976d2)' 
      : grey[400],
  },
  transition: 'background 0.3s ease',
});

const questionBoxStyle = {
  width: '100%',
  p: 2,
  mb: 2,
  borderRadius: 12,
  bgcolor: blue[50],
  border: `1px solid ${blue[200]}`,
  boxShadow: `0px 4px 12px rgba(0, 0, 0, 0.08)`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'all 0.3s ease',
};

const chatContainerStyle = {
  width: '100%',
  maxHeight: '40vh',
  overflowY: 'auto',
  mb: 2,
  border: `1px solid ${grey[300]}`,
  borderRadius: 12,
  p: 2,
  backgroundColor: grey[50],
  boxShadow: `0px 2px 8px rgba(0, 0, 0, 0.06)`,
};

const chatBubbleStyle = {
  p: 2,
  borderRadius: 16,
  mb: 1,
  maxWidth: '75%',
  fontSize: '0.9rem',
  transition: 'background 0.3s ease',
};

const userBubbleStyle = {
  ...chatBubbleStyle,
  bgcolor: green[100],
  border: `1px solid ${green[200]}`,
  alignSelf: 'flex-end',
};

const candidateBubbleStyle = {
  ...chatBubbleStyle,
  bgcolor: grey[100],
  border: `1px solid ${grey[300]}`,
  alignSelf: 'flex-start',
};

const closeButtonStyle = {
  color: grey[600],
  '&:hover': {
    color: red[600],
  },
};

const CandidateInteractionModal = ({ open, onClose, questions, candidateData }) => {
  const [currentView, setCurrentView] = useState('conversation');
  const [expanded, setExpanded] = useState(false);

  if (!candidateData) return null;

  const { current_question, questions_list } = candidateData;
  const questionsList = JSON.parse(questions_list || '{}');

  const isConversationActive = currentView === 'conversation';
  const isQuestionsActive = currentView === 'questions';

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box sx={headerStyle}>
          <Typography variant="h6">
            {isConversationActive ? 'Candidate Conversation' : 'Questions for the Candidate'}
          </Typography>
          <IconButton sx={closeButtonStyle} onClick={onClose}>
            <Close />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <Button
            variant="contained"
            onClick={() => setCurrentView('conversation')}
            sx={tabButtonStyle(isConversationActive)}
          >
            Conversation
          </Button>
          <Button
            variant="contained"
            onClick={() => setCurrentView('questions')}
            sx={tabButtonStyle(isQuestionsActive)}
          >
            Questions
          </Button>
        </Box>

        {currentView === 'conversation' && (
          <>
            <Paper elevation={3} sx={questionBoxStyle} onClick={() => setExpanded(!expanded)}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Current Question
              </Typography>
              <IconButton onClick={() => setExpanded(!expanded)} size="small">
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Paper>

            {expanded && (
              <Paper
                elevation={1}
                sx={{
                  width: '100%',
                  p: 2,
                  mb: 2,
                  borderRadius: 12,
                  bgcolor: blue[50],
                  border: `1px solid ${blue[200]}`,
                  transition: 'all 0.3s ease',
                }}
              >
                <Typography variant="body2">{current_question || 'No data available'}</Typography>
              </Paper>
            )}

            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" sx={{ fontWeight: 600, mb: 2 }}>
              Conversation:
            </Typography>
            <Box sx={chatContainerStyle}>
              {Object.keys(questionsList).length > 0 ? (
                <List>
                  {Object.entries(questionsList).map(([question, answer], index) => (
                    <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
                      <Box sx={userBubbleStyle}>
                        <Typography variant="body2">{question}</Typography>
                      </Box>
                      <Box sx={candidateBubbleStyle}>
                        <Typography variant="body2">{answer || 'No answer provided'}</Typography>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography variant="body2" color="text.secondary" align="center">
                  No data available
                </Typography>
              )}
            </Box>
          </>
        )}

        {currentView === 'questions' && (
          <Box sx={{ maxHeight: '55vh', overflowY: 'auto' }}>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" sx={{ fontWeight: 600, mb: 2 }}>
              Questions:
            </Typography>
            {questions.length > 0 ? (
              <List>
                {questions.map((question, index) => (
                  <ListItem key={index}>
                    <Paper sx={questionBoxStyle}>
                      <ListItemText
                        primary={
                          <Typography variant="body1" sx={{ fontWeight: 600 }}>
                            Q{index + 1}: {question}
                          </Typography>
                        }
                        // secondary={
                        //   <Typography variant="body2" color="text.secondary">
                        //     Please provide your response below.
                        //   </Typography>
                        // }
                      />
                    </Paper>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2" color="text.secondary" align="center">
                No data available
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CandidateInteractionModal;
