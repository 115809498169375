import React from "react";
import { Box, Typography, TextField } from "@mui/material";

const BankAccountDetails = () => {
  return (
    <Box>
      <Typography variant="h6">Bank Account Details</Typography>
      <TextField label="Bank Name" fullWidth margin="normal" />
      <TextField label="Account Number" fullWidth margin="normal" />
      <TextField label="IFSC Code" fullWidth margin="normal" />
    </Box>
  );
};

export default BankAccountDetails;
