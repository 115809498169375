import React, { useState } from "react";
import { Box, Typography, TextField, Button, useTheme } from "@mui/material";

const ChangePassword = () => {
  const theme = useTheme();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordChange = () => {
    // Logic for password change
    if (newPassword === confirmPassword) {
      console.log("Password updated");
    } else {
      console.log("Passwords do not match");
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Change Password
      </Typography>

      <Typography variant="body2" color="textSecondary" gutterBottom>
        Enter your current password and a new password to update.
      </Typography>

      <TextField
        label="Current Password"
        type="password"
        fullWidth
        margin="normal"
        variant="outlined"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <TextField
        label="New Password"
        type="password"
        fullWidth
        margin="normal"
        variant="outlined"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        label="Confirm New Password"
        type="password"
        fullWidth
        margin="normal"
        variant="outlined"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />

      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
          padding: theme.spacing(1.5, 3),
        }}
        onClick={handlePasswordChange}
      >
        Update Password
      </Button>
    </Box>
  );
};

export default ChangePassword;
