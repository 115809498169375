import React from "react";
import { Container, Typography, Button, Box, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "../SideBar"; // Adjust the import based on your file structure

const NotActivated = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/myprofile"); // Redirect to profile page
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar /> {/* Render the sidebar */}
      <Box component="main" sx={{ flexGrow: 1, mt:6, p: 3 }}>
      <Toolbar />
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "#f5f5f5",
            borderRadius: 2,
            boxShadow: 3,
            p: { xs: 2, md: 4 }, // Responsive padding
            width: "60vw", // Full width for smaller screens
            margin: "0 auto", // Center the container
          }}
        >
          <img
            src="./access_denied.png"
            alt="Not Activated"
            style={{
              width: "50vw", // Make image responsive
              maxWidth: "300px", // Max width for larger screens
              height: "auto",
              borderRadius: 8,
              marginBottom: 20,
            }}
          />
          <Typography variant="h4" gutterBottom sx={{ color: "#ff5722", textAlign: 'center' }}>
            Your Account Is Not Activated
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 3, color: "#666", textAlign: 'center' }}>
            Your account is not activated yet. An admin will activate it within
            24 hours.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirect}
            sx={{ borderRadius: 2 }}
          >
            Update Profile
          </Button>
        </Container>
</Box>
    </Box>
  );
};

export default NotActivated;
