import React, { useState } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  TextField,
  Box,
  Paper,
  Tooltip,
  IconButton,
  Modal,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import BreadCrumb from '../BreadCrumb';
import Sidebar from '../SideBar';

// Define custom styles for the component
const useStyles = () => {
  const theme = useTheme();
  return {
    profileCard: {
      background: 'linear-gradient(to bottom right, #e0f7fa, #b2ebf2)',
      boxShadow: theme.shadows[3],
      borderRadius: theme.shape.borderRadius,
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    infoCard: {
      background: 'linear-gradient(to bottom right, #ffffff, #f1f8e9)',
      boxShadow: theme.shadows[3],
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    modalContent: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '600px',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3),
    },
    cardTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    cardSubtitle: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(2),
    },
    avatar: {
      width: 150,
      height: 150,
      border: `5px solid ${theme.palette.primary.main}`,
    },
    button: {
      marginTop: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
    },
    iconButton: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
  };
};

const UserProfile = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const handleOpenModal = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const profileData = {
    name: 'John Doe',
    pic: 'https://via.placeholder.com/150',
    bankDetails: 'Bank of Example, Account Number: 1234567890',
    currentAmount: '$1,500.00',
    currentCredits: '200',
    amountReceivedTillDate: '$5,000.00',
    amountThatCanBeRedeemed: '$500.00',
    redeemLink: '#',
    pendingAmount: '$100.00',
    pendingCredits: '20',
    candidatesJoined: '5',
    paymentDue: '2024-12-01',
    candidatesNotJoined: '2',
    resumesNotVerified: '1',
    skills: 'JavaScript, React, Node.js',
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Sidebar />
      <Container>
        <Box mt={12} sx={{ width: '100%', typography: 'body1' }}>
          <BreadCrumb />
          <Snackbar
            open={show}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            autoHideDuration={6000}
            onClose={() => setShow(false)}
          >
            <Alert onClose={() => setShow(false)} severity={severity} sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
          <Grid container spacing={3} mt={4}>
            <Grid item xs={12} md={4}>
              <Card sx={classes.profileCard}>
                <Avatar src={profileData.pic} alt="Profile" sx={classes.avatar} />
                <Typography variant="h5" sx={classes.cardTitle}>
                  {profileData.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={classes.cardSubtitle}>
                  {profileData.bankDetails}
                </Typography>
                <Divider sx={classes.divider} />
                <Typography variant="h6">Current Amount: {profileData.currentAmount}</Typography>
                <Typography variant="h6">Current Credits: {profileData.currentCredits}</Typography>
                <Typography variant="h6">Amount Received Till Date: {profileData.amountReceivedTillDate}</Typography>
                <Typography variant="h6">Amount That Can Be Redeemed: {profileData.amountThatCanBeRedeemed}</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href={profileData.redeemLink}
                  sx={classes.button}
                >
                  Redeem Now
                </Button>
              </Card>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper sx={classes.infoCard}>
                <Typography variant="h6" gutterBottom>
                  Pending Amount and Credits
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ boxShadow: 2, p: 2 }}>
                      <CardContent>
                        <Typography variant="h6">Pending Amount</Typography>
                        <Typography variant="body1">{profileData.pendingAmount}</Typography>
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{ mt: 1 }}
                          onClick={() => handleOpenModal('Pending Amount Details')}
                        >
                          View Details
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card sx={{ boxShadow: 2, p: 2 }}>
                      <CardContent>
                        <Typography variant="h6">Pending Credits</Typography>
                        <Typography variant="body1">{profileData.pendingCredits}</Typography>
                        <Button
                          variant="outlined"
                          color="secondary"
                          sx={{ mt: 1 }}
                          onClick={() => handleOpenModal('Pending Credits Details')}
                        >
                          View Details
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Divider sx={classes.divider} />
                <Typography variant="h6" gutterBottom>
                  Additional Information
                </Typography>
                <Typography variant="body1">
                  <b>Candidates Joined:</b> {profileData.candidatesJoined}
                </Typography>
                <Typography variant="body1">
                  <b>Payment Due:</b> {profileData.paymentDue}
                </Typography>
                <Typography variant="body1">
                  <b>Candidates Not Joined Yet:</b> {profileData.candidatesNotJoined}
                </Typography>
                <Typography variant="body1">
                  <b>Uploaded Resume Not Verified Yet:</b> {profileData.resumesNotVerified}
                </Typography>
                <Divider sx={classes.divider} />
                <Typography variant="h6" gutterBottom>
                  Skills
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={profileData.skills}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={classes.textField}
                />
                <Tooltip title="Edit Skills" placement="top">
                  <IconButton
                    sx={classes.iconButton}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Paper>
            </Grid>
          </Grid>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={classes.modalContent}>
              <Typography id="modal-title" variant="h6" component="h2">
                {modalContent}
              </Typography>
              <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained">
                Close
              </Button>
            </Box>
          </Modal>
        </Box>
      </Container>
    </Box>
  );
};

export default UserProfile;
