import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Button,
  useTheme,
  styled,
  Container,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PaidOutlined from "@mui/icons-material/PaidOutlined";
import RedeemIcon from "@mui/icons-material/Redeem";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import InfoIcon from "@mui/icons-material/Info";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline"; // New icon for TopCard
import Sidebar from "../SideBar";

// Enhanced color palette with gradients and contrast
const colors = {
  tealGradient: "linear-gradient(135deg, #1e888c 0%, #0ac4cb 100%)",
  greenGradient: "linear-gradient(135deg, #60c68e 0%, rgb(150 242 192 / 86%))",
  darkGray: "#2a2a2a",
  lightText: "#fafafa",
};

// Custom styled components
const CardWrapper = styled(Card)(({ theme, bgcolor }) => ({
  borderRadius: 16,
  boxShadow: theme.shadows[3],
  background: bgcolor,
  color: colors.lightText,
  height: "100%",
  padding: theme.spacing(2),
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[8],
  },
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  padding: theme.spacing(3),
  background: `linear-gradient(135deg, #3b8d99 0%, #6b8da1 100%)`,
  color: colors.lightText,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: theme.shadows[5],
  height: "100%",
  minHeight: "400px",
}));

const TopCard = styled(Box)(({ theme }) => ({
  background: `linear-gradient(340deg, #1e888c 0%, #0ac4cb 100%)`,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  color: colors.lightText,
  textAlign: "center",
  marginBottom: theme.spacing(4),
  display: "flex",
  flexDirection: "row", // Changed to row for layout
  alignItems: "center",
  justifyContent: "space-between", // Align items to left and right
  boxShadow: theme.shadows[5],
  position: "relative",
}));

const Dashboard = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", mt: 6, px: 3 }}>
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - 240px)`,
        }}
      >
        <Container className="home-wrapper">
          {/* Welcome Section */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TopCard>
                {/* Left section with text */}
                <Box>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Welcome to Jinn Hire
                  </Typography>
                  <Typography variant="body1">
                    A recruitment platform to help you find your dream job and hire the best talent.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ mt: 2, background: colors.greenGradient }}
                  >
                    View Requirements
                  </Button>
                </Box>
                {/* Right section with icon */}
                <WorkOutlineIcon
                  sx={{
                    fontSize: 100,
                    color: colors.accentPurple,
                    opacity: 0.8,
                  }}
                />
              </TopCard>
            </Grid>
          </Grid>

          {/* Dashboard Layout */}
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {/* Profile Card */}
            <Grid item xs={12} md={4}>
              <ProfileCard>
                <Typography variant="h6" gutterBottom>
                  John Doe
                </Typography>
                <Typography variant="body2">Profile Complete: 85%</Typography>
                <Button
                  variant="contained"
                  sx={{ mt: 2, background: colors.greenGradient }}
                >
                  Edit Profile
                </Button>
              </ProfileCard>
            </Grid>

            {/* Main Dashboard Cards */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                {/* Total Balance */}
                <Grid item xs={12} sm={6}>
                  <CardWrapper bgcolor={colors.tealGradient}>
                    <CardContent>
                      <Typography variant="h6">
                        <MonetizationOnIcon
                          sx={{ fontSize: 30, color: colors.accentPurple }} // Changed icon color to purple
                        />{" "}
                        Total Balance
                      </Typography>
                      <Typography variant="h4" sx={{ color: colors.accentPurple }}>
                        $12,912
                      </Typography>
                    </CardContent>
                  </CardWrapper>
                </Grid>

                {/* Amount Received */}
                <Grid item xs={12} sm={6}>
                  <CardWrapper bgcolor={colors.greenGradient}>
                    <CardContent>
                      <Typography variant="h6">
                        <PaidOutlined
                          sx={{ fontSize: 30, color: colors.accentPurple }} // Changed icon color to purple
                        />{" "}
                        Amount Received
                      </Typography>
                      <Typography variant="h4" sx={{ color: colors.accentPurple }}>
                        $78,910
                      </Typography>
                    </CardContent>
                  </CardWrapper>
                </Grid>

                {/* Amount Redeemable */}
                <Grid item xs={12} sm={6}>
                  <CardWrapper bgcolor={colors.tealGradient}>
                    <CardContent>
                      <Typography variant="h6">
                        <RedeemIcon
                          sx={{ fontSize: 30, color: colors.accentPurple }} // Changed icon color to purple
                        />{" "}
                        Amount Redeemable
                      </Typography>
                      <Typography variant="h4" sx={{ color: colors.accentPurple }}>
                        $3,456
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{ mt: 2, background: colors.greenGradient }}
                      >
                        Redeem
                      </Button>
                    </CardContent>
                  </CardWrapper>
                </Grid>

                {/* Pending Amount */}
                <Grid item xs={12} sm={6}>
                  <CardWrapper bgcolor={colors.tealGradient}>
                    <CardContent>
                      <Typography variant="h6">
                        <PendingActionsIcon
                          sx={{ fontSize: 30, color: colors.accentPurple }} // Changed icon color to purple
                        />{" "}
                        Pending Amount
                        <Tooltip
                          title={
                            <div>
                              <Typography variant="body2">
                                Details on pending amounts.
                              </Typography>
                            </div>
                          }
                          arrow
                        >
                          <IconButton sx={{ color: colors.accentPurple }}>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <Typography variant="h4" sx={{ color: colors.accentPurple }}>
                        $1,234
                      </Typography>
                    </CardContent>
                  </CardWrapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Dashboard;