import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Divider,
  IconButton,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import SelectAllIcon from '@mui/icons-material/CheckBox';
import UnselectAllIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import axios from 'axios';

const UserSelectionModal = ({ open, onClose, users, onSelectUsers, customerId }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const token = localStorage.getItem("token");

  const handleToggleUser = (user) => {
    const currentIndex = selectedUsers.indexOf(user);
    const newSelectedUsers = [...selectedUsers];

    if (currentIndex === -1) {
      newSelectedUsers.push(user);
    } else {
      newSelectedUsers.splice(currentIndex, 1);
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handleSelectAll = (divisionUsers) => {
    const newSelectedUsers = selectedUsers.length === divisionUsers.length
      ? selectedUsers.filter(user => !divisionUsers.includes(user))
      : [...new Set([...selectedUsers, ...divisionUsers])];

    setSelectedUsers(newSelectedUsers);
  };

  const handleConfirm = async () => {
    const userProfileIds = selectedUsers.map(user => user.user_profile_id);

    const payload = {
      customer_id: customerId,
      userprofile_ids: userProfileIds,
    };

    try {
      const response = await axios.post('https://api.jinnhire.in/jinnhire/data/userprofile-customers/assign-users/', payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      console.log('API Response:', response.data);
      onSelectUsers(selectedUsers);
      onClose();
    } catch (error) {
      console.error('Error assigning users:', error);
    }
  };

  const groupedUsers = users.reduce((acc, user) => {
    if (!acc[user.role_division]) {
      acc[user.role_division] = [];
    }
    acc[user.role_division].push(user);
    return acc;
  }, {});

  const formatUsername = (username) => {
    const nameBeforeAt = username.split("@")[0];
    return nameBeforeAt.replace(/\./g, " ");
  };

  const divisions = Object.keys(groupedUsers);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle color="#003366">Choose Users to Assign for the Customer</DialogTitle>
      <DialogContent>
        <Tabs
          value={tabIndex}
          onChange={(e, newValue) => setTabIndex(newValue)}
          centered
          sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: '#fafafa' }}
        >
          {divisions.map((division, index) => (
            <Tab
              key={division}
              label={division}
              sx={{
                color: tabIndex === index ? 'white' : '#1976d2',
              }}
            />
          ))}
        </Tabs>
        <Divider sx={{ mb: 2 }} />
        <Box>
          {divisions.map((division, index) => (
            tabIndex === index && (
              <div key={division}>
                <TableContainer sx={{ 
                  maxHeight: 300, // Set the maximum height for the scrollable area
                  backgroundColor: '#f4f6f9', 
                  borderRadius: 1,
                  overflowY: 'auto', // Enable vertical scroll
                }}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#1c426d' }}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            indeterminate={selectedUsers.length > 0 && selectedUsers.length < groupedUsers[division].length}
                            checked={selectedUsers.length === groupedUsers[division].length}
                            onChange={() => handleSelectAll(groupedUsers[division])}
                          />
                        </TableCell>
                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Username</TableCell>
                        <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Role</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupedUsers[division].length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={3} align="center" sx={{ py: 3, color: '#888' }}>
                            No data available
                          </TableCell>
                        </TableRow>
                      ) : (
                        groupedUsers[division].map((user) => (
                          <TableRow
                            key={user.id}
                            hover
                            onClick={() => handleToggleUser(user)}
                            sx={{
                              '&:hover': { backgroundColor: '#e0e0e0' },
                              backgroundColor: selectedUsers.indexOf(user) !== -1 ? '#cfe2ff' : 'white',
                            }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUsers.indexOf(user) !== -1} />
                            </TableCell>
                            <TableCell>{formatUsername(user.username)}</TableCell>
                            <TableCell>{user.role}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider />
              </div>
            )
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="success" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSelectionModal;
