import React, { createContext, useState, useEffect, useMemo } from "react";
import axios from "axios";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  // States for global settings
  const [resumeAssignmentStrategy, setResumeAssignmentStrategy] = useState("");
  const [globalSurplusCount, setGlobalSurplusCount] = useState(0);
  const [globalAssignmentType, setGlobalAssignmentType] = useState("");

  // States for customer data
  const [customerData, setCustomerData] = useState([]);
  const [loadingCustomerData, setLoadingCustomerData] = useState(false);
  const [errorCustomerData, setErrorCustomerData] = useState(null);

  // States for requirements
  const [requirements, setRequirements] = useState([]);
  const [loadingRequirements, setLoadingRequirements] = useState(false);
  const [errorRequirements, setErrorRequirements] = useState(null);
  const userId = localStorage.getItem("userId"); // Assuming userId is stored in localStorage
  const queryTime = new Date().toISOString(); // Set your queryTime here

  // States for users
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [errorUsers, setErrorUsers] = useState(null);

  // Function to fetch global settings
  const fetchGlobalSettings = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    try {
      const response = await fetch(
        "https://api.jinnhire.in/jinnhire/data/global-settings/",
        {
          method: "GET",
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setGlobalSurplusCount(data.global_surplus_count_of_resumes);
        setGlobalAssignmentType(data.global_assignment_type);
        setResumeAssignmentStrategy(data.resume_assignment_strategy);
      } else {
        throw new Error("Failed to fetch global settings");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to fetch customer data
  const fetchCustomerData = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    setLoadingCustomerData(true);
    try {
      const response = await axios.get(
        "https://api.jinnhire.in/jinnhire/data/customers/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setCustomerData(response.data);
        setErrorCustomerData(null); // Clear previous errors
      } else {
        throw new Error("Failed to fetch customer data");
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setErrorCustomerData("Failed to load customer data");
    } finally {
      setLoadingCustomerData(false);
    }
  };

  // Function to fetch requirements
  const fetchRequirements = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    setLoadingRequirements(true);
    try {
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/requirements/?t=${queryTime}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      const requirementsData = response.data;

      setRequirements(requirementsData);
    } catch (error) {
      setErrorRequirements(
        error.response?.data?.detail ||
          "An error occurred while fetching requirements."
      );
    } finally {
      setLoadingRequirements(false);
    }
  };

  // Function to fetch users
  const fetchUsers = async () => {
    const token = localStorage.getItem("token");
    if (!token) return;

    setLoadingUsers(true);
    try {
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/users/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setUsers(response.data);
      setFilteredUsers(response.data.filter((user) => !user.is_active)); // Only show inactive users
      setErrorUsers(null); // Clear previous errors
    } catch (error) {
      console.error("Error fetching users:", error);
      setErrorUsers("Failed to load users");
    } finally {
      setLoadingUsers(false);
    }
  };

  // Fetch global settings, customer data, and requirements once on mount
  useEffect(() => {
    fetchGlobalSettings();
    fetchCustomerData();
    fetchRequirements();
    fetchUsers();
  }, []);

  // Memoize context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      // Global settings
      resumeAssignmentStrategy,
      setResumeAssignmentStrategy,
      globalSurplusCount,
      globalAssignmentType,

      // Customer data
      customerData,
      loadingCustomerData,
      errorCustomerData,
      fetchCustomerData,
      fetchGlobalSettings,

      // Requirements data
      requirements,
      loadingRequirements,
      errorRequirements,
      fetchRequirements,

      // Users data
      users,
      filteredUsers,
      loadingUsers,
      errorUsers,
      fetchUsers,
    }),
    [
      resumeAssignmentStrategy,
      globalSurplusCount,
      globalAssignmentType,
      customerData,
      loadingCustomerData,
      errorCustomerData,
      requirements,
      loadingRequirements,
      errorRequirements,
      users,
      filteredUsers,
      loadingUsers,
      errorUsers,
    ]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
