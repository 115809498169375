import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Container } from "@mui/material";

const cardStyle = {
  position: 'relative',
  textAlign: 'center',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', 
  alignItems: 'center',
};

const imgStyle = {
  width: '100%', 
  height: 'auto', 
  maxWidth: '50%',
};

const textStyle = {
  color: '#333333', // Adjust text color as needed
  fontSize: '1.5rem', // Adjust font size
  lineHeight: '1.6', // Line height for readability
  textAlign: 'center', // Center-align text
};

const NoDataCard = () => {
  return (
    <Container
    style={{ backgroundColor: "#ffff", boxShadow: "none" }}
  >
    <Card sx={cardStyle}>
      <CardContent>
        <img src="./No_data.png" alt="No Data" style={imgStyle} />
        <Typography variant="body1" sx={textStyle}>
          Please opt-in a requirement / No Profiles Found
        </Typography>
      </CardContent>
    </Card>
    </Container>
  );
};

export default NoDataCard;
