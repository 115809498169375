import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
  Divider,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

const ResumeLinksModal = ({ open, onClose, resumeLinks }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}>
        Resume Links
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box sx={{ padding: 2 }}>
          {resumeLinks.length > 0 ? (
            <Grid container spacing={2}>
              {resumeLinks.map(([key, link]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <Tooltip title={key.replace('_', ' ')}>
                    <Button
                      variant="contained"
                      fullWidth
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        '&:hover': { backgroundColor: '#115293' },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px 16px',
                        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <Typography 
                        variant="body1" 
                        sx={{ textAlign: 'left', flexGrow: 1 }}
                      >
                        {key.replace('_', ' ').length > 15
                          ? `${key.replace('_', ' ').substring(0, 12)}...`
                          : key.replace('_', ' ')}
                      </Typography>
                      <LaunchIcon sx={{ color: 'white' }} />
                    </Button>
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" color="textSecondary" align="center">
              No links available
            </Typography>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
        <Button 
          onClick={onClose} 
          variant="outlined" 
          color="primary" 
          sx={{ borderRadius: '20px' }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResumeLinksModal;
