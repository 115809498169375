import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Divider,
  TextField,
  Grid,
  Button,
  Container,
  Tabs,
  Tab,
  Paper,
  IconButton,
  useTheme,
  styled,
  Toolbar,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Input
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import Sidebar from "../SideBar";
import BreadCrumb from "../BreadCrumb";
import BankAccountDetails from "./BankAccountDetails";
import Payment from "./Payment";
import ChangePassword from "./ChangePassword";

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#f5f7fa",
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    padding: theme.spacing(2),
  },
}));

const LeftCard = styled(Card)(({ theme }) => ({
  flex: "0 1 300px",
  marginRight: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[8],
  borderRadius: theme.shape.borderRadius,
}));

const RightContent = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[8],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  border: `1px solid ${theme.palette.divider}`,
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  margin: "auto",
  marginBottom: theme.spacing(2),
  border: `3px solid ${theme.palette.primary.main}`,
  boxShadow: theme.shadows[2],
}));

const UpperCard = styled(Card)(({ theme }) => ({
  background: "linear-gradient(277deg, #0099ff 0%, #5b8abf 100%)",
  padding: theme.spacing(2),
  color: "#fff",
  marginBottom: theme.spacing(4),
  textAlign: "center",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const SkillChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: theme.palette.background.paper,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: "#fff",
  },
  "& .MuiChip-deleteIcon": {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: "#fff",
    },
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

const MyProfile = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [skills, setSkills] = useState([
    "Adobe XD", "Angular", "Coral Draw", "Figma", "HTML", "Illustrator", 
    "Javascript", "Logo Design", "Material UI", "NodeJS", "Photoshop", "React", "SASS", "SQL Server",
  ]);
  const [newSkill, setNewSkill] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setUserData(data);
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleAddSkill = () => {
    if (newSkill) {
      setSkills([...skills, newSkill]);
      setNewSkill("");
    }
  };

  const handleRemoveSkill = (skillToRemove) => {
    setSkills(skills.filter((skill) => skill !== skillToRemove));
  };

  const handleProfilePictureUpload = (event) => {
    if (event.target.files[0]) {
      setProfilePicture(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container className="home-wrapper">
          <BreadCrumb />

          <UpperCard>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Edit Your Profile
            </Typography>
            <Typography>Complete your profile to unlock all features</Typography>
            <Tooltip title={editMode ? "Cancel Edit" : "Edit Profile"}>
              <IconButton
                sx={{ position: "absolute", top: theme.spacing(2), right: theme.spacing(2), color: "#fff" }}
                onClick={handleEditToggle}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </UpperCard>

          <ProfileContainer>
            <LeftCard>
              <CardContent>
                <ProfileAvatar
                  src={profilePicture || userData?.profilePicture || "/path-to-default-profile-picture.jpg"}
                  alt="Profile Picture"
                />
                <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "bold" }}>
                  {userData?.username.split("@")[0] || "N/A"}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: "center", color: "gray" }}>
                  Full Stack Developer
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="body2" sx={{ mb: 2, color: theme.palette.text.secondary }}>
                  <strong>Available Credits:</strong> 100
                  <br />
                  <strong>Redeemed Credits:</strong> 20
                </Typography>
                
                <Divider sx={{ my: 2 }} />
                
                <Tabs
                  orientation="vertical"
                  value={selectedTab}
                  onChange={handleTabChange}
                  textColor="primary"
                  indicatorColor="primary"
                  variant="fullWidth"
                  sx={{
                    "& .MuiTab-root:hover": {
                      backgroundColor: "#e0f7fa",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#b2ebf2",
                      fontWeight: "bold",
                    },
                  }}
                >
                  <Tab label="Personal Information" />
                  <Tab label="Bank Account Details" />
                  <Tab label="Payment" />
                  <Tab label="Change Password" />
                  <Tab label="Settings" />
                </Tabs>
              </CardContent>
            </LeftCard>

            <RightContent>
              {selectedTab === 0 && (
                <Box>
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    Personal Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="First Name"
                        fullWidth
                        value={userData?.firstName || ""}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Last Name"
                        fullWidth
                        value={userData?.lastName || ""}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email Address"
                        fullWidth
                        value={userData?.email || ""}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone Number"
                        fullWidth
                        value={userData?.phone_number || ""}
                        disabled={!editMode}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Designation"
                        fullWidth
                        value={userData?.role || "Full Stack Developer"}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Skills</Typography>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        {skills.map((skill, index) => (
                          <SkillChip
                            key={index}
                            label={skill}
                            onDelete={editMode ? () => handleRemoveSkill(skill) : undefined}
                            deleteIcon={<DeleteIcon />}
                          />
                        ))}
                      </Box>
                      {editMode && (
                        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                          <TextField
                            label="Add Skill"
                            fullWidth
                            value={newSkill}
                            onChange={(e) => setNewSkill(e.target                            .value)}
                            sx={{ marginRight: 1 }}
                          />
                          <IconButton color="primary" onClick={handleAddSkill}>
                            <AddIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Grid>
                    {editMode && (
                      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                        <Input
                          type="file"
                          onChange={handleProfilePictureUpload}
                          sx={{ display: 'none' }}
                          id="upload-profile-picture"
                        />
                        <label htmlFor="upload-profile-picture">
                          <UploadButton variant="contained" component="span" startIcon={<UploadIcon />}>
                            Upload Profile Picture
                          </UploadButton>
                        </label>
                      </Box>
                    )}
                  </Grid>

                  {editMode && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                      <Button variant="contained" color="primary" sx={{ mr: 2 }}>
                        Save
                      </Button>
                      <Button variant="outlined" color="secondary" onClick={handleEditToggle}>
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Box>
              )}

              {selectedTab === 1 && <BankAccountDetails />}
              {selectedTab === 2 && <Payment />}
              {selectedTab === 3 && <ChangePassword />}
              {/* Additional components can be added as needed */}
            </RightContent>
          </ProfileContainer>
        </Container>
      </Box>
    </Box>
  );
};

export default MyProfile;
