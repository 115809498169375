import React, { useState } from "react";
import { Box, Typography, TextField, Button, Divider, useTheme } from "@mui/material";

const Payment = () => {
  const theme = useTheme();
  const [availableCredits, setAvailableCredits] = useState(500); // Example credits
  const [redeemedCredits, setRedeemedCredits] = useState(200); // Example redeemed

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Payment Information
      </Typography>
      <Divider sx={{ mb: 2 }} />
      
      {/* Available and Redeemed Credits */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box>
          <Typography variant="body1">Available Credits</Typography>
          <Typography variant="h4" color="primary">
            {availableCredits}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">Redeemed Credits</Typography>
          <Typography variant="h4" color="secondary">
            {redeemedCredits}
          </Typography>
        </Box>
      </Box>

      {/* Payment Details */}
      <Typography variant="body1" gutterBottom>
        Add or update your payment details below.
      </Typography>
      <TextField
        label="Payment Method (e.g., PayPal, Bank Transfer)"
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Account Number"
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        sx={{
          mt: 2,
          padding: theme.spacing(1.5, 3),
        }}
      >
        Save Payment Details
      </Button>
    </Box>
  );
};

export default Payment;
