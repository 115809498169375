import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Avatar,
  Tooltip,
  Modal,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Skeleton,
  TextField,
  Toolbar,
  Container,
  Tabs,
  Tab,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { green, grey, red } from "@mui/material/colors";
import DownloadIcon from "@mui/icons-material/GetApp";
import ApproveIcon from "@mui/icons-material/Check";
import RejectIcon from "@mui/icons-material/Close";
import CandidateEvaluationModal from "./CandidateEvaluationModal";
import {
  CheckCircle,
  Info,
  SearchOutlined,
  Warning,
} from "@mui/icons-material";
import Sidebar from "./SideBar";
import BreadCrumb from "./BreadCrumb";
import TopBar from "./TopBar";

const checkboxStyles = {
  positive: { color: "green" },
  negative: { color: "red" },
};
const itemsPerPage = 9;
const ITEMS_PER_PAGE = 5;

const MyResumes = () => {
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [modalContent, setModalContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commentInput, setCommentInput] = useState("");
  const [searchQuerySkipped, setSearchQuerySkipped] = useState("");
  const [searchQueryProcessed, setSearchQueryProcessed] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userId, setuserId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [processedResumes, setProcessedResumes] = useState([]);
  const [page, setPage] = useState(1);
  const suitableOptions = [
    {
      text: "Candidate is interested in the position",
      style: checkboxStyles.positive,
    },
    { text: "Candidate is strong technically", style: checkboxStyles.positive },
    { text: "Candidate will join", style: checkboxStyles.positive },
    { text: "Candidate can join immediately", style: checkboxStyles.positive },
    { text: "Candidate seems genuine", style: checkboxStyles.positive },
  ];

  const notSuitableOptions = [
    {
      text: "Candidate is not interested in the role",
      style: checkboxStyles.negative,
    },
    { text: "Candidate has high expectations", style: checkboxStyles.negative },
    { text: "Candidate may not join", style: checkboxStyles.negative },
    { text: "Candidate notice period is high", style: checkboxStyles.negative },
    { text: "Candidate is not responding", style: checkboxStyles.negative },
    {
      text: "Candidate is not suitable/ irrelevant",
      style: checkboxStyles.negative,
    },
    {
      text: "Candidate is not looking for change",
      style: checkboxStyles.negative,
    },
    { text: "Candidate might not relocate", style: checkboxStyles.negative },
    {
      text: "Candidate is not having good communication skills",
      style: checkboxStyles.negative,
    },
    {
      text: "Candidate is not having good technical skills",
      style: checkboxStyles.negative,
    },
    {
      text: "Other (Please specify below)",
      style: checkboxStyles.negative,
    },
  ];

  const handleCheckboxChange = (text, checked) => {
    setSelectedOptions((prev) =>
      checked ? [...prev, text] : prev.filter((option) => option !== text)
    );
  };

  const handleInputChange = (event) => {
    setCommentInput(event.target.value);
  };

  const handleOpenModal = (isSuitable, profile) => {
    setModalContent(isSuitable ? suitableOptions : notSuitableOptions);
    setSelectedProfile(profile);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOptions([]);
    setSelectedOptions([]);
  };

  const handleSubmit = async () => {
    if (!selectedProfile || !selectedOptions.length) return;
    // Filter out "Other (Please specify below)" from selectedOptions and join the rest
    const filteredOptions = selectedOptions.filter(
      (option) => option !== "Other (Please specify below)"
    );

    // Initialize remarks with the filtered options
    let remarks = filteredOptions.join(", ");

    // Append only the user input if "Other (Please specify below)" is selected
    if (
      selectedOptions.includes("Other (Please specify below)") &&
      commentInput.trim() !== ""
    ) {
      remarks += (remarks ? ", " : "") + commentInput;
    }

    try {
      const token = localStorage.getItem("token");
      const state = selectedOptions.some((option) =>
        suitableOptions.some((suitableOption) => suitableOption.text === option)
      )
        ? "processed"
        : "processing_failed";

      const resumeResponse = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/resume-insights/get-resume-by-phone/`,
        {
          params: { phone_number: selectedProfile.phone_number },
          headers: { Authorization: `Token ${token}` },
        }
      );

      const resumeData = resumeResponse.data;

      await axios.patch(
        `https://api.jinnhire.in/jinnhire/data/resumes/${resumeData.resume_id}/update-state/`,
        { state, remarks, validity: null, processing_user_id: userId },
        { headers: { Authorization: `Token ${token}` } }
      );
      setCommentInput("");
      await axios.post(
        `https://api.jinnhire.in/jinnhire/data/resumes/${resumeData.resume_id}/unlock/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );

      await axios.put(
        `https://api.jinnhire.in/jinnhire/data/candidate-state-machines/${selectedProfile.candidate_phone_number}/`,
        { candidate_processing_state: state },
        { headers: { Authorization: `Token ${token}` } }
      );

      setShow(true);
      setSeverity("success");
      setMessage("Successfully updated candidate state.");
      setSelectedProfile(null);
      fetchData(); // Refresh profiles after submission
    } catch (error) {
      console.error("Error submitting", error);
      setShow(true);
      setSeverity("error");
      setMessage(
        error.response?.data?.message || "Failed to update candidate state."
      );
    }
    handleCloseModal();
    fetchData(); // Refresh profiles after submission
  };

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      setuserId(parsedUserData.id);
    }
  }, []);

  useEffect(() => {
    if (userId !== null) {
      fetchData();
      fetchProcessedData();
    }
  }, [userId]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.jinnhire.in/jinnhire/data/skip_comments/by_user/",
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
          params: { user_id: userId },
        }
      );

      const resumes = {};
      const now = new Date();
      const todayEnd = new Date(now.setHours(23, 59, 59, 999));
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(now.getDate() - 7);

      response.data.forEach((item) => {
        const commentDate = new Date(item.comment.created_at);
        if (commentDate >= sevenDaysAgo && commentDate <= todayEnd) {
          if (resumes[item.resume.resume_id]) {
            resumes[item.resume.resume_id].comments.push(item.comment);
          } else {
            resumes[item.resume.resume_id] = {
              ...item.resume,
              comments: [item.comment],
            };
          }
        }
      });
      // Filter out items with state "communication_failed" or requirement_id null
      const filteredResumes = Object.values(resumes).filter(
        (resume) =>
          resume.state === "communication_failed" &&
          resume.requirement_id !== null
      );

      // Sort comments with latest first
      filteredResumes.forEach((resume) => {
        resume.comments.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      });

      setData(filteredResumes);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDateTime = (datetime) => {
    const date = new Date(datetime);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const formatRecruiterName = (email) => {
    if (!email) return "Unknown Recruiter";
    const name = email.split("@")[0];
    return name.replace(".", " ");
  };

  const handleDownload = (file_link) => {
    window.open(file_link, "_blank");
  };

  // Filtering functions
  const filteredSkippedResumes = data.filter((resume) => {
    const firstInsight = resume.insights?.[0];
    const fullName = firstInsight
      ? `${firstInsight.first_name} ${firstInsight.last_name}`
      : "";
    return fullName.toLowerCase().includes(searchQuerySkipped.toLowerCase());
  });

  const filteredProcessedResumes = processedResumes.filter((resume) => {
    const firstInsight = resume.insights?.[0];
    const fullName = firstInsight
      ? `${firstInsight.first_name} ${firstInsight.last_name}`
      : "";
    return fullName.toLowerCase().includes(searchQueryProcessed.toLowerCase());
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) setSearchQueryProcessed("");
    if (newValue === 1) setSearchQuerySkipped("");
  };

  // Function to fetch processed resumes when the tab changes to "Processed Resumes"
  const fetchProcessedData = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .get("https://api.jinnhire.in/jinnhire/data/resumes/by_state/", {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        params: {
          state: "processed",
          user_id: userId, // Replace with actual user ID if needed
        },
      })
      .then((response) => {
        setProcessedResumes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching processed resumes:", error);
        setLoading(false);
      });
  };

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredSkippedResumes.length / ITEMS_PER_PAGE);

  // Calculate the current items to display
  const startIndexes = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = filteredSkippedResumes.slice(
    startIndexes,
    startIndexes + ITEMS_PER_PAGE
  );

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const currentResumes = filteredProcessedResumes.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <Box sx={{ display: "flex", bgcolor: "#f4f6f8" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>
          <BreadCrumb />
          <Box sx={{ padding: 3 }}>
            <Snackbar
              open={show}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              autoHideDuration={6000}
              message="Success"
              onClose={() => setShow(false)}
            >
              <Alert
                onClose={() => setShow(false)}
                severity={severity}
                sx={{
                  width: "100%",
                  bgcolor: severity === "error" ? "red.500" : "green.500",
                }}
              >
                {message}
              </Alert>
            </Snackbar>

            <Grid container spacing={2}>
              {/* Skipped Resumes Count */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    backgroundColor: "#d4e2ec",
                    color: "#023770",
                    mb: 2,
                    borderRadius: 3,
                    boxShadow: 3,
                    transition: "0.3s",
                    "&:hover": { boxShadow: 6, transform: "scale(1.02)" },
                    position: "relative",
                  }}
                >
                  <CardContent sx={{ display: "flex", alignItems: "center" }}>
                    <Warning sx={{ fontSize: 40, color: "#023770", mr: 2 }} />{" "}
                    {/* Matching color */}
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Skipped Resumes
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        {data.length}
                      </Typography>
                    </Box>
                  </CardContent>
                  {/* Search input for Skipped Resumes */}
                  {tabValue === 0 && (
                    <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
                      <TextField
                        variant="outlined"
                        placeholder="Search candidate..."
                        sx={{
                          borderRadius: 2,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: grey[400],
                            },
                            "&:hover fieldset": {
                              borderColor: grey[600],
                            },
                          },
                          "& .MuiInputBase-input": {
                            padding: "12px 14px",
                            fontSize: "1rem",
                          },
                        }}
                        value={searchQuerySkipped}
                        onChange={(e) => {
                          setSearchQuerySkipped(e.target.value);
                          setCurrentPage(1);
                        }}
                        InputProps={{
                          endAdornment: (
                            <SearchOutlined sx={{ color: grey[600], mr: 1 }} />
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Card>
              </Grid>

              {/* Processed Resumes Count */}
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    backgroundColor: "#d0f0c0",
                    color: "#023770",
                    mb: 2,
                    borderRadius: 3,
                    boxShadow: 3,
                    transition: "0.3s",
                    "&:hover": { boxShadow: 6, transform: "scale(1.02)" },
                    position: "relative",
                  }}
                >
                  <CardContent sx={{ display: "flex", alignItems: "center" }}>
                    <CheckCircle
                      sx={{ fontSize: 40, color: "#023770", mr: 2 }}
                    />{" "}
                    <Box sx={{ flexGrow: 1 }}>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Processed Resumes
                      </Typography>
                      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        {processedResumes.length}
                      </Typography>
                    </Box>
                  </CardContent>
                  {/* Search input for Processed Resumes */}
                  {tabValue === 1 && (
                    <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
                      <TextField
                        variant="outlined"
                        placeholder="Search candidate..."
                        sx={{
                          borderRadius: 2,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: grey[400],
                            },
                            "&:hover fieldset": {
                              borderColor: grey[600],
                            },
                          },
                          "& .MuiInputBase-input": {
                            padding: "12px 14px",
                            fontSize: "1rem",
                          },
                        }}
                        value={searchQueryProcessed}
                        onChange={(e) => {
                          setSearchQueryProcessed(e.target.value);
                          setPage(1);
                        }}
                        InputProps={{
                          endAdornment: (
                            <SearchOutlined sx={{ color: grey[600], mr: 1 }} />
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>

            {/* Tabs for Skipped and Processed Resumes */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                bgcolor: "#f0f4ff",
                p: 2,
                borderRadius: 1,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="resume tabs"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#023770",
                  },
                }}
              >
                <Tab
                  label="Skipped Resumes"
                  sx={{
                    color: tabValue === 0 ? "#023770" : "text.secondary",
                    "&.Mui-selected": {
                      color: "#023770",
                    },
                  }}
                />
                <Tab
                  label="Processed Resumes"
                  sx={{
                    color: tabValue === 1 ? "#023770" : "text.secondary",
                    "&.Mui-selected": {
                      color: "#023770",
                    },
                  }}
                />
              </Tabs>
            </Box>

            {/* Content based on active tab */}
            <Box sx={{ mt: 2 }}>
              {tabValue === 0 && (
                <Box>
                  {loading ? (
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      {[...Array(3)].map((_, index) => (
                        <Card
                          key={index}
                          sx={{
                            marginBottom: 2,
                            borderRadius: 2,
                            boxShadow: 3,
                            backgroundColor: "#f9f9f9",
                          }}
                        >
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Skeleton
                                  variant="text"
                                  width="60%"
                                  height={30}
                                />
                                <Skeleton
                                  variant="text"
                                  width="80%"
                                  height={20}
                                />
                                <Skeleton
                                  variant="text"
                                  width="70%"
                                  height={20}
                                />
                                <Skeleton
                                  variant="text"
                                  width="50%"
                                  height={20}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height={100}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      ))}
                    </Box>
                  ) : filteredSkippedResumes.length === 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: 4,
                      }}
                    >
                      <Info sx={{ fontSize: 60, color: "#195c8b" }} />
                      <Typography
                        variant="h6"
                        sx={{ marginTop: 2, color: "#195c8b" }}
                      >
                        No Data Available
                      </Typography>
                    </Box>
                  ) : (
                    currentItems.map((resume, index) => (
                      <Card
                        key={index}
                        sx={{
                          marginBottom: 2,
                          borderRadius: 2,
                          boxShadow: 3,
                          backgroundColor: "#ffffff",
                          transition: "0.3s",
                          "&:hover": {
                            boxShadow: 6,
                            transform: "scale(1.02)",
                          },
                        }}
                      >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle1" color="primary">
                                Candidate:{" "}
                                {resume.insights?.[0]?.first_name || ""}{" "}
                                {resume.insights?.[0]?.last_name || ""}
                              </Typography>
                              <Typography variant="body2">
                                Phone: {resume.phone_number}
                              </Typography>
                              <Typography variant="body2">
                                Requirement ID: {resume.requirement_id}
                              </Typography>
                              <Typography variant="body2">
                                Location:{" "}
                                {resume.insights?.[0]?.current_location}
                              </Typography>
                              <Typography variant="body2">
                                Remarks: {resume.remarks || "N/A"}
                              </Typography>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography variant="body2">
                                    Mandatory Skills
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table size="small">
                                    <TableBody>
                                      {Object.entries(
                                        resume.insights?.[0]
                                          ?.mandatory_skills || {}
                                      ).map(([skill, value], idx) => (
                                        <TableRow key={idx}>
                                          <TableCell>{skill}</TableCell>
                                          <TableCell>{value}</TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="body2">Comments:</Typography>
                              <Box
                                sx={{
                                  maxHeight: 100,
                                  overflowY: "auto",
                                  borderRadius: 1,
                                  padding: 1,
                                  border: "1px solid #e0e0e0",
                                }}
                              >
                                {resume.comments.map((comment, idx) => (
                                  <Box
                                    key={idx}
                                    display="flex"
                                    alignItems="flex-start"
                                    mb={1}
                                    p={1}
                                    borderRadius="12px"
                                    bgcolor="#f1f1f1"
                                  >
                                    <Avatar
                                      sx={{
                                        bgcolor: "#3a6db7",
                                        mr: 1,
                                        width: 30,
                                        height: 30,
                                      }}
                                    >
                                      {formatRecruiterName(
                                        comment.recruiter_name
                                      )
                                        .charAt(0)
                                        .toUpperCase()}
                                    </Avatar>
                                    <Box sx={{ flexGrow: 1 }}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        mb={0.5}
                                      >
                                        <Typography
                                          variant="caption"
                                          sx={{ fontWeight: "medium" }}
                                        >
                                          {formatRecruiterName(
                                            comment.recruiter_name
                                          )}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          color="textSecondary"
                                        >
                                          {formatDateTime(comment.created_at)}
                                        </Typography>
                                      </Box>
                                      <Typography variant="caption">
                                        {comment.comment || "N/A"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                              <Box sx={{ marginTop: 1 }}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="success"
                                  startIcon={<ApproveIcon />}
                                  sx={{ mx: 0.5 }}
                                  onClick={() => handleOpenModal(true, resume)}
                                >
                                  Approve
                                </Button>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="error"
                                  startIcon={<RejectIcon />}
                                  sx={{ mx: 0.5 }}
                                  onClick={() => handleOpenModal(false, resume)}
                                >
                                  Reject
                                </Button>
                                <Tooltip title="Download Resume">
                                  <IconButton
                                    color="primary"
                                    onClick={() =>
                                      handleDownload(resume.file_link)
                                    }
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))
                  )}

                  {/* Pagination Controls */}
                  {totalPages > 1 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                        bgcolor: "#e3e3e3",
                        padding: 2,
                        borderRadius: 2,
                        boxShadow: 2,
                      }}
                    >
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        sx={{
                          "& .MuiPaginationItem-root": {
                            borderRadius: 2,
                            margin: "0 2px",
                            "&.Mui-selected": {
                              backgroundColor: "#023770",
                              color: "#fff",
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}

              {tabValue === 1 && (
                <Box sx={{ backgroundColor: "#f0f4f8", padding: 2 }}>
                  {/* Content for Processed Resumes */}
                  {loading ? (
                    <Grid container spacing={3}>
                      {Array.from({ length: itemsPerPage }).map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Skeleton
                            variant="rectangular"
                            height={140}
                            sx={{ borderRadius: "16px" }}
                          />
                          <Skeleton variant="text" height={30} sx={{ mt: 1 }} />
                          <Skeleton
                            variant="text"
                            height={20}
                            sx={{ mt: 0.5 }}
                          />
                          <Skeleton
                            variant="text"
                            height={20}
                            sx={{ mt: 0.5 }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : currentResumes.length === 0 ? (
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        align="center"
                        color="textSecondary"
                        gutterBottom
                      >
                        No Data Available
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid container spacing={3}>
                      {currentResumes.map((resume, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <Card
                            sx={{
                              backgroundColor: "#fffff",
                              borderRadius: "16px",
                              border: "1px solid #e0e0e0",
                              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                              transition: "transform 0.2s",
                              "&:hover": {
                                transform: "scale(1.03)",
                                boxShadow: "0px 12px 40px rgba(0, 0, 0, 0.15)",
                                borderColor: "#2196f3",
                              },
                            }}
                          >
                            <CardContent
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "200px",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                color="primary"
                                sx={{ fontWeight: "bold" }}
                              >
                                Candidate:{" "}
                                {resume.insights?.[0]?.first_name || ""}{" "}
                                {resume.insights?.[0]?.last_name || ""}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <strong>Phone:</strong> {resume.phone_number}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                <strong>Location:</strong>{" "}
                                {resume.insights?.[0]?.current_location}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{ overflowWrap: "break-word", flexGrow: 1 }}
                              >
                                <strong>Remarks:</strong>{" "}
                                {resume.remarks || "N/A"}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}

                  {/* Pagination Controls */}
                  {filteredProcessedResumes.length > itemsPerPage && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 2,
                        bgcolor: "#e3e3e3",
                        padding: 2,
                        borderRadius: 2,
                        boxShadow: 2,
                      }}
                    >
                      <Pagination
                        count={Math.ceil(
                          filteredProcessedResumes.length / itemsPerPage
                        )}
                        page={page}
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                        sx={{
                          "& .MuiPaginationItem-root": {
                            borderRadius: 2,
                            margin: "0 2px",
                            "&.Mui-selected": {
                              backgroundColor: "#023770",
                              color: "#fff",
                            },
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <CandidateEvaluationModal
              modalOpen={modalOpen}
              handleCloseModal={handleCloseModal}
              handleCheckboxChange={handleCheckboxChange}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              selectedOptions={selectedOptions}
              commentInput={commentInput}
              modalContent={modalContent}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default MyResumes;
