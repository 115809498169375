import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  CircularProgress,
  Container,
  Grid,
  Toolbar,
  Paper,
} from "@mui/material";
import { AppContext } from "../../AppContext";
import { green } from "@mui/material/colors";
import Sidebar from "../../SideBar";
import BreadCrumb from "../../BreadCrumb";

const GlobalSettings = () => {
  const [globalSurplusCount, setGlobalSurplusCount] = useState(2);
  const [globalAssignmentType, setGlobalAssignmentType] = useState("");
  const { resumeAssignmentStrategy, setResumeAssignmentStrategy } =
    useContext(AppContext);
  const [demoMode, setDemoMode] = useState(false);
  const [settings, setSettings] = useState({
    tenant_auto_communication_enabled: false,
    tenant_license_applied: "FULL",
    tenant_default_number_of_resumes_to_download: 30,
    tenant_id: "",
  });

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem("token");

      try {
        const globalResponse = await axios.get(
          "https://api.jinnhire.in/jinnhire/data/global-settings/",
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        const globalData = globalResponse.data;
        setGlobalSurplusCount(globalData.global_surplus_count_of_resumes);
        setGlobalAssignmentType(globalData.global_assignment_type);
        setResumeAssignmentStrategy(globalData.resume_assignment_strategy);
        setDemoMode(globalData.demo_mode);

        const loggedInUserEmail = localStorage.getItem("username");
        const loggedInUserDomain = loggedInUserEmail.split("@")[1];
        const tenantResponse = await axios.get(
          `https://api.jinnhire.in/jinnhire/data/tenants/current/?domain=${loggedInUserDomain}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        setSettings(tenantResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching settings:", error);
        setLoading(false);
      }
    };

    fetchSettings();
  }, [setResumeAssignmentStrategy]);

  const handleUpdateSettings = async () => {
    const token = localStorage.getItem("token");

    try {
      await axios.post(
        "https://api.jinnhire.in/jinnhire/data/global-settings/set_settings/",
        {
          global_surplus_count_of_resumes: globalSurplusCount,
          global_assignment_type: globalAssignmentType,
          resume_assignment_strategy: resumeAssignmentStrategy,
          demo_mode: demoMode,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      await axios.post(
        "https://api.jinnhire.in/jinnhire/data/tenants/set-tenant-settings/",
        {
          tenant_auto_communication_enabled:
            settings.tenant_auto_communication_enabled,
          tenant_license_applied: settings.tenant_license_applied,
          tenant_default_number_of_resumes_to_download:
            settings.tenant_default_number_of_resumes_to_download,
          tenant_id: settings.id,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      setShow(true);
      setSeverity("success");
      setMessage("Settings updated successfully");
    } catch (error) {
      console.error("Error updating settings:", error);
      setShow(true);
      setSeverity("error");
      setMessage("Failed to update settings. Please try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container>
          <BreadCrumb />
          <Snackbar
            open={show}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            autoHideDuration={6000}
            onClose={() => setShow(false)}
          >
            <Alert
              onClose={() => setShow(false)}
              severity={severity}
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
          <Paper sx={{ p: 4, backgroundColor: "" }}>
            <Box mt={2}>
              <Typography variant="h6" mb={1}>
                Global Settings
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>Global Surplus Count of Resumes</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    type="number"
                    value={globalSurplusCount}
                    onChange={(e) => setGlobalSurplusCount(e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography>Assignment Type</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth size="small">
                    <Select
                      value={globalAssignmentType}
                      onChange={(e) => setGlobalAssignmentType(e.target.value)}
                    >
                      <MenuItem value="roundrobin_global_active_byload">
                        Round Robin Global Active by Load
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <Typography>Assignment Strategy</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth size="small">
                    <Select
                      value={resumeAssignmentStrategy}
                      onChange={(e) =>
                        setResumeAssignmentStrategy(e.target.value)
                      }
                    >
                      <MenuItem value="roundrobin_opted_users">
                        Round Robin Opted Users
                      </MenuItem>
                      <MenuItem value="pool_based">Pool Based</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <Typography>Demo Mode</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Switch
                    name="demo_mode"
                    checked={demoMode}
                    onChange={(e) => setDemoMode(e.target.checked)}
                    color="success"
                  />
                  <Typography component="span">
                    {demoMode ? "Yes" : "No"}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="h6" mt={4} mb={1}>
                Tenant Settings
              </Typography>

              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography>Auto Communication Enabled</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Switch
                    name="tenant_auto_communication_enabled"
                    checked={settings.tenant_auto_communication_enabled}
                    onChange={handleInputChange}
                    color="success"
                  />
                  <Typography component="span">
                    {settings.tenant_auto_communication_enabled ? "Yes" : "No"}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography>License Applied</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl fullWidth size="small">
                    <Select
                      name="tenant_license_applied"
                      value={settings.tenant_license_applied}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="FULL">FULL</MenuItem>
                      <MenuItem value="TRIAL">TRIAL</MenuItem>
                      <MenuItem value="FREE">FREE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <Typography>Default Resumes to Download</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    name="tenant_default_number_of_resumes_to_download"
                    type="number"
                    value={
                      settings.tenant_default_number_of_resumes_to_download
                    }
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateSettings}
                  sx={{
                    width: "200px",
                    bgcolor: green[500],
                    "&:hover": { bgcolor: green[700] },
                  }}
                >
                  Update Settings
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default GlobalSettings;
