import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Chip,
  Box,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
  Pagination,
  Skeleton,
} from "@mui/material";
import {
  Search as SearchIcon,
  Person as PersonIcon,
  Cancel,
  CancelOutlined,
} from "@mui/icons-material";

const UserActivate = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const usersPerPage = 6;

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `https://api.jinnhire.in/jinnhire/data/users/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setUsers(response.data);
      setFilteredUsers(response.data.filter(user => !user.is_active)); // Only show inactive users
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  const handleActivate = async (userId) => {
    try {
      await axios.post(
        `https://api.jinnhire.in/jinnhire/data/users/${userId}/user_activate/`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setShow(true);
      setSeverity("success");
      setMessage("User activated successfully!");
      fetchUsers();
    } catch (error) {
      console.error("Error activating user:", error);
      setShow(true);
      setSeverity("error");
      setMessage("Error activating user. Please try again.");
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filtered = users.filter(user =>
      !user.is_active && user.username.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredUsers(filtered);
    setPage(1); // Reset to page 1 after search
  };

  const formatUsername = (username) => {
    const nameBeforeAt = username.split("@")[0]; // Take the part before "@"
    return nameBeforeAt.replace(/\./g, " "); // Replace dots with spaces
  };

  // Pagination Logic
  const indexOfLastUser = page * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Snackbar
        open={show}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
        onClose={() => setShow(false)}
      >
        <Alert
          onClose={() => setShow(false)}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Box display="flex" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>
        Users
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search by username"
          value={search}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Grid container spacing={3}>
        {loading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton
                variant="rectangular"
                height={140}
                sx={{ borderRadius: "16px" }}
              />
              <Skeleton variant="text" height={30} sx={{ mt: 1 }} />
              <Skeleton variant="text" height={20} sx={{ mt: 0.5 }} />
              <Skeleton variant="text" height={20} sx={{ mt: 0.5 }} />
            </Grid>
          ))
        ) : currentUsers.length === 0 ? (
          <Grid item xs={12}>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              gutterBottom
            >
              No inactive users available
            </Typography>
          </Grid>
        ) : (
          currentUsers.map((user) => (
            <Grid item xs={12} sm={6} md={4} key={user.id}>
              <Card
                sx={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "16px",
                  boxShadow: "0px 6px 24px rgba(128, 128, 128, 0.2)",
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                  border: "1px solid #d9d9d9",
                }}
              >
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" alignItems="center">
                      <PersonIcon
                        sx={{
                          fontSize: 48,
                          color:"lightgray",
                          marginRight: 2,
                        }}
                      />
                      <Box>
                        <Typography variant="h6" sx={{ fontWeight: "bold", overflowWrap: 'break-word' }}>
                          {formatUsername(user.username)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ overflowWrap: 'break-word' }}>
                          {user.role}
                        </Typography>
                      </Box>
                    </Box>
                    <CancelOutlined color="error"/>
                
                  </Box>

                  <Typography variant="body2" color="textSecondary" sx={{ overflowWrap: 'break-word' }}>
                    <strong>Email:</strong> {user.email}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>Phone:</strong> {user.phone_number}
                  </Typography>
                </CardContent>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mb: 1,
                    background: "linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)",
                    color: "white",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "#21cbf3",
                    },
                  }}
                  onClick={() => handleActivate(user.id)}
                >
                  Activate User
                </Button>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Pagination
          count={Math.ceil(filteredUsers.length / usersPerPage)}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default UserActivate;
