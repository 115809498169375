import React from 'react';
import { Box, Typography, Container, Paper, Toolbar } from '@mui/material';
import Sidebar from '../SideBar';
import TopBar from '../TopBar';

const underConstructionImage = "./contact1.svg";

const ContactUs = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container className="home-wrapper">
          <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', borderRadius: 2 }}>
            <Box sx={{ mb: 4 }}>
              <img
                src={underConstructionImage}
                alt="Under Construction"
                style={{ width: '30vw', height: 'auto', objectFit: 'contain' }}
              />
            </Box>
            <Typography variant="h3" component="h1" gutterBottom sx={{ mb: 2 }}>
              Page Under Construction
            </Typography>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
              We are currently working on this page. Please check back later for more information.
            </Typography>
            <Typography variant="body1" color="textSecondary">
              For urgent inquiries, please email us at <a href="mailto:info@nexiilabs.com">info@nexiilabs.com</a>.
            </Typography>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default ContactUs;
