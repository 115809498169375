import React from "react";
import { Breadcrumbs, Link, Typography, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Home as HomeIcon } from "@mui/icons-material";

const BreadCrumb = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Box sx={{ mb:2 }}>
      <Breadcrumbs aria-label="breadcrumb" separator="›" sx={{ fontSize: '16px' }}>
        <Link
          color="inherit"
          onClick={() => navigate("/home")}
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', textDecoration: 'none', '&:hover': { textDecoration: 'underline', color: '#4a90e2' } }}
        >
          <HomeIcon sx={{ mr: 0.5, color: '#4a90e2' }} fontSize="inherit" />
          <Typography sx={{ color: '#1F3446' }}>Dashboard</Typography>
        </Link>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography color="textPrimary" key={to} sx={{ color: '#1F3446' }}>
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Typography>
          ) : (
            <Link
              color="inherit"
              onClick={() => navigate(to)}
              key={to}
              sx={{ cursor: 'pointer', textDecoration: 'none', color: '#4a90e2', '&:hover': { textDecoration: 'underline' } }}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadCrumb;
