import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Tabs,
  Tab,
  Chip,
  Toolbar,
  TableSortLabel,
  IconButton,
  Tooltip,
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
} from "@mui/material";
import Sidebar from "./SideBar";
import BreadCrumb from "./BreadCrumb";
import { ExpandMore, Launch, Refresh, Visibility } from "@mui/icons-material";
import ResumeLinksModal from "./ResumeLinksModal";
import { AppContext } from "./AppContext";
import DataModal from "./DataModal";
import { CheckCircle, HourglassEmpty, Warning } from "@mui/icons-material";
import { blue, green, orange, red, grey } from "@mui/material/colors";

const Reports = () => {
  const [loading, setLoading] = useState(true);
  const [resumeReports, setResumeReports] = useState([]);
  const [recruiterReports, setRecruiterReports] = useState([]);
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const { requirements, users, fetchRequirements } = useContext(AppContext);
  const [division, setDivison] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRecruiter, setSelectedRecruiter] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentLinks, setCurrentLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenModal = (links) => {
    setCurrentLinks(links);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentLinks([]);
  };

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const getrole = JSON.parse(userData);
    setDivison(getrole.role_division);

    fetchReports();
  }, [users, division, selectedRecruiter]);

  const fetchReports = async () => {
    try {
      const token = localStorage.getItem("token");

      // Fetch Recruiter Reports
      const recruiterResponse = await axios.get(
        "https://api.jinnhire.in/jinnhire/data/manager_reports/recruiter_performance/",
        {
          headers: { Authorization: `Token ${token}` },
        }
      );

      // Fetch Resume Reports
      const resumeResponse = await axios.get(
        "https://api.jinnhire.in/jinnhire/data/autopilot-reports/",
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
      // Process recruiter reports to add role_division
      // const updatedRecruiterReports = recruiterResponse.data.map((report) => {
      //   const user = users.find((user) => user.id === report.recruiter_id);
      //   return {
      //     ...report,
      //     role_division: user ? user.role_division : null, // Add role_division if user is found
      //   };
      // });

      // Process and filter recruiter reports
      const updatedRecruiterReports = recruiterResponse.data
        .map((report) => {
          const user = users.find((user) => user.id === report.recruiter_id);
          return {
            ...report,
            role_division: user ? user.role_division : null,
          };
        })
        .filter((report) => {
          // Filter by division
          return division === "all" || report.role_division === division;
        });

      setRecruiterReports(updatedRecruiterReports);
      setResumeReports(resumeResponse.data.reverse());
      setLoading(false);
    } catch (error) {
      setError("Failed to fetch reports");
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleRecruiterChange = (event) => {
    setSelectedRecruiter(event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatRecruiterName = (email) => {
    const name = email.split("@")[0];
    return name.replace(".", " ");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewType, setViewType] = useState(""); // Default view
  // Recruiter Reports Data Processing
  const recruiterNames = [
    ...new Set(recruiterReports.map((report) => report.recruiter_name)),
  ];

  const filteredRecruiterReports = selectedRecruiter
    ? recruiterReports.filter(
        (report) => report.recruiter_name === selectedRecruiter
      )
    : recruiterReports;

  const totalProcessedResumes = filteredRecruiterReports.reduce(
    (total, report) => total + report.total_processed,
    0
  );
  const totalFailed = filteredRecruiterReports.reduce(
    (total, report) => total + report.processing_failed,
    0
  );
  const totalProcessed = totalProcessedResumes + totalFailed;

  const flattenedRecruiterReports = filteredRecruiterReports.flatMap((report) =>
    report.processed_resumes
      .filter((resume) => resume.resume_state === "processed") // Only include processed resumes
      .map((resume) => ({
        recruiter_name: report.recruiter_name,
        requirement_id: resume.requirement_id,
        candidate_name: resume.candidate_name,
        resume_state: resume.resume_state,
      }))
  );

  // Map all requirement_ids from the requirements array
  const requirementIdsToMatch = requirements.map((req) => req.requirement_id);

  // Filter the reports based on the requirement_ids
  const filteredReports = resumeReports.filter(
    (report) => requirementIdsToMatch.includes(report.requirement) // Check if the report's requirement is in the list of IDs
  );

  // Pagination logic
  const displayResumeReports = filteredReports.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Display filtered reports
  const displayFilteredReports =
    searchQuery.trim() === "" ? displayResumeReports : filteredReports;

  // Logic to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRefresh = () => {
    // Function to fetch requirements again
    fetchRequirements();
    fetchReports();
  };

  const displayRecruiterReports = flattenedRecruiterReports.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Filter reports based on autopilot_processing_state
  const autopilotReports = requirements.filter(
    (report) => report.autopilot_processing_state === "No"
  );
  // Filter reports based on autopilot_processing_state
  const autopilotSuccessReports = requirements.filter(
    (report) => report.autopilot_processing_state === "Yes"
  );
  const handleOpenDataModal = (type) => {
    setViewType(type);
    setIsModalOpen(true);
  };
  const handleCloseDataModal = () => setIsModalOpen(false);

  const processingPilots = autopilotReports.filter(
    (report) =>
      report.processing_status === "processing" &&
      report.autopilot_processing_state === "No"
  );
  const waitingPilots = autopilotReports.filter(
    (report) =>
      report.processing_status === null &&
      report.autopilot_processing_state === "No"
  );
  const failedPilots = autopilotSuccessReports.filter(
    (report) => report.processing_status === "failed"
  );

  const totalAutopilots = autopilotReports.length;
  const totalProcessing = processingPilots.length;
  const totalWaiting = waitingPilots.length;
  const totalFailedPilots = failedPilots.length;

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: { xs: 1, md: 3 } }}>
        <Toolbar />
        <Container className="home-wrapper">
          <BreadCrumb />

          {/* Tabs for switching between Recruiter Reports and Resume Reports */}
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              centered // Center the tabs within their container
            >
              <Tab label="Recruiter Reports" />
              <Tab label="Resume Reports" />
            </Tabs>
            {tabValue === 0 ? (
              // Recruiter Reports Tab
              <Box>
                <Box p={2}>
                  {/* Recruiter Filter */}
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <InputLabel>Select Recruiter</InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <Select
                          value={selectedRecruiter}
                          onChange={handleRecruiterChange}
                          displayEmpty
                        >
                          <MenuItem value="">All Recruiters</MenuItem>
                          {recruiterNames.map((name, index) => (
                            <MenuItem key={index} value={name}>
                              {formatRecruiterName(name)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                <Paper elevation={3} sx={{ borderRadius: 2 }}>
                  <TableContainer>
                    <Table>
                      <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                        <TableRow>
                          <TableCell
                            sx={{ fontWeight: "bold", color: "white" }}
                          >
                            <TableSortLabel>SNO</TableSortLabel>
                          </TableCell>
                          {selectedRecruiter === "" && (
                            <TableCell
                              sx={{ fontWeight: "bold", color: "white" }}
                            >
                              <TableSortLabel>Recruiter Name</TableSortLabel>
                            </TableCell>
                          )}
                          <TableCell
                            sx={{ fontWeight: "bold", color: "white" }}
                          >
                            <TableSortLabel>Requirement ID</TableSortLabel>
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "bold", color: "white" }}
                          >
                            <TableSortLabel>Candidate Name</TableSortLabel>
                          </TableCell>
                          <TableCell
                            sx={{ fontWeight: "bold", color: "white" }}
                          >
                            <TableSortLabel>Status</TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        ) : error ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <Typography variant="h6" color="error">
                                {error}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : displayRecruiterReports.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <Typography variant="h6" color="textSecondary">
                                No data available
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          displayRecruiterReports.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              {selectedRecruiter === "" && (
                                <TableCell>
                                  {formatRecruiterName(row.recruiter_name)}
                                </TableCell>
                              )}
                              <TableCell>{row.requirement_id}</TableCell>
                              <TableCell>{row.candidate_name}</TableCell>
                              <TableCell>
                                <Chip
                                  label="Submitted"
                                  color="success"
                                  variant="outlined"
                                  sx={{
                                    backgroundColor: "#e0f7fa",
                                    borderColor: "#009688",
                                    color: "#00796b",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box
                    p={2}
                    display="flex"
                    justifyContent="space-between"
                    sx={{ backgroundColor: "#fafafa" }}
                  >
                    <Typography variant="h6" color="textSecondary">
                      Submitted Resumes: {totalProcessedResumes}
                    </Typography>
                    <Typography variant="h6" color="error">
                      Process Failed: {totalFailed}
                    </Typography>
                    <Typography variant="h6" color="primary">
                      Total Processed: {totalProcessed}
                    </Typography>
                  </Box>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={flattenedRecruiterReports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              </Box>
            ) : (
              // Resume Reports Tab
              <Box p={2}>
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    label="Search by Requirement ID"
                    variant="outlined"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    sx={{ width: 300 }}
                  />
                  <IconButton
                    onClick={handleRefresh}
                    sx={{
                      mb: 2,
                      backgroundColor: "rgb(31, 91, 139)", // Default background color
                      color: "#fff", // Default icon color
                      "&:hover": {
                        backgroundColor: "rgb(21, 71, 99)", // Darker shade on hover
                        color: "#e0e0e0", // Lighter icon color on hover
                      },
                      borderRadius: 2,
                      transition: "background-color 0.3s, color 0.3s", // Smooth transition
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Card
                      sx={{
                        backgroundColor: "#edd3d2",
                        color: red[800],
                        mb: 2,
                        borderRadius: 2,
                        boxShadow: 3,
                        transition: "0.3s",
                        "&:hover": { boxShadow: 6 },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Warning
                            sx={{ fontSize: 40, color: red[600], mr: 2 }}
                          />
                          <div>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Failed Autopilots
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{ fontWeight: "bold" }}
                            >
                              {totalFailedPilots}
                            </Typography>
                          </div>
                        </div>
                        <Tooltip title="View Failed Autopilots" arrow>
                          <IconButton
                            onClick={() => handleOpenDataModal("failed")}
                            sx={{ color: red[600] }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card
                      sx={{
                        backgroundColor: "#ebdecd",
                        color: orange[800],
                        mb: 2,
                        borderRadius: 2,
                        boxShadow: 3,
                        transition: "0.3s",
                        "&:hover": { boxShadow: 6 },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <HourglassEmpty
                            sx={{ fontSize: 40, color: orange[600], mr: 2 }}
                          />
                          <div>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Processing Pilots
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{ fontWeight: "bold" }}
                            >
                              {totalProcessing}
                            </Typography>
                          </div>
                        </div>
                        <Tooltip title="View Processing Pilots" arrow>
                          <IconButton
                            onClick={() => handleOpenDataModal("processing")}
                            sx={{ color: orange[600] }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Card
                      sx={{
                        backgroundColor: "#c7ebc7",
                        color: green[800],
                        mb: 2,
                        borderRadius: 2,
                        boxShadow: 3,
                        transition: "0.3s",
                        "&:hover": { boxShadow: 6 },
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CheckCircle
                            sx={{ fontSize: 40, color: green[600], mr: 2 }}
                          />
                          <div>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Waiting Pilots
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{ fontWeight: "bold" }}
                            >
                              {totalWaiting}
                            </Typography>
                          </div>
                        </div>
                        <Tooltip title="View Waiting Pilots" arrow>
                          <IconButton
                            onClick={() => handleOpenDataModal("waiting")}
                            sx={{ color: green[600] }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                {/* Resume Reports Table */}
                <Paper
                  elevation={3}
                  sx={{ borderRadius: 2, overflow: "hidden" }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead sx={{ backgroundColor: "#1976d2" }}>
                        <TableRow>
                          {[
                            "S.No",
                            "Requirement ID",
                            "Request Time",
                            "Resumes Requested",
                            "Resumes Submitted",
                            "Status",
                            "Resume Links",
                          ].map((header) => (
                            <TableCell
                              key={header}
                              sx={{
                                fontWeight: "bold",
                                color: "white",
                                borderBottom: "2px solid #005cbf",
                              }}
                            >
                              {header}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        ) : error ? (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography variant="h6" color="error">
                                {error}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : displayFilteredReports.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              <Typography variant="h6" color="textSecondary">
                                No data available
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          displayFilteredReports.map((report, index) => (
                            <TableRow key={report.id} hover>
                              <TableCell>
                                {page * rowsPerPage + index + 1}
                              </TableCell>
                              <TableCell
                                sx={{
                                  maxWidth: 200,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Tooltip title={report.requirement} arrow>
                                  <span>{report.requirement}</span>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                {new Date(
                                  report.request_datetime
                                ).toLocaleString()}
                              </TableCell>
                              <TableCell>
                                {report.num_resumes_requested}
                              </TableCell>
                              <TableCell>
                                {report.num_resumes_submitted}
                              </TableCell>
                              <TableCell>
                                <Chip
                                  label={report.autopilot_processing_status}
                                  variant="outlined"
                                  sx={{
                                    borderColor:
                                      report.autopilot_processing_status ===
                                      "passed"
                                        ? "#388e3c"
                                        : report.autopilot_processing_status ===
                                          "failed"
                                        ? "#d32f2f"
                                        : "#fbc02d",
                                    color:
                                      report.autopilot_processing_status ===
                                      "passed"
                                        ? "#388e3c"
                                        : report.autopilot_processing_status ===
                                          "failed"
                                        ? "#d32f2f"
                                        : "#fbc02d",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Button
                                  className="button"
                                  variant="contained"
                                  sx={{ backgroundColor: "rgb(37 86 145)" }}
                                  onClick={() =>
                                    handleOpenModal(
                                      Object.entries(
                                        report.resume_names_and_links
                                      )
                                    )
                                  }
                                >
                                  View Resumes
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  {/* Pagination */}
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={filteredReports.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{ borderTop: "1px solid #e0e0e0" }}
                  />
                </Paper>
              </Box>
            )}
          </Paper>
          <ResumeLinksModal
            open={openModal}
            onClose={handleCloseModal}
            resumeLinks={currentLinks}
          />
          {/* Use the DataModal component here */}
          <DataModal
            open={isModalOpen}
            onClose={handleCloseDataModal}
            processingPilots={processingPilots}
            waitingPilots={waitingPilots}
            failedPilots={failedPilots}
            viewType={viewType}
          />
        </Container>
      </Box>
    </Box>
  );
};

export default Reports;
